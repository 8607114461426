// UserContext.js
import React, { createContext, useState } from 'react';
import api from '../utils/api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const fetchUserData = async () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const response = await api.get('/auth/user/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setUser(response.data);
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        setIsAuthenticated(false);
        setUser(null);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);  
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{ isAuthenticated, user, setIsAuthenticated, fetchUserData, handleLogout }}
    >
      {children}
    </UserContext.Provider>
  );
};
