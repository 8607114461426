import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap
import Anna1 from '../../assets/Anna_1.svg'; // Import the image from your assets
import api from '../../utils/api'; // Import the API utility
import { UserContext } from '../../context/UserContext'; // Import UserContext to manage auth state

const GuidanceLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State for loading
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(UserContext); // Get setIsAuthenticated from UserContext
  const [shouldValidate, setShouldValidate] = useState(false); // Add this line

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await api.post('/auth/guidance-login/', { admin: email, password });
  
      // If response is successful and contains tokens
      if (response.status === 200 && response.data.access && response.data.refresh) {
        const { access, refresh } = response.data;
  
        // Validate user role
        const userDetails = await api.get('api/check_login_status/', {
          headers: { Authorization: `Bearer ${access}` },
        });
  
        const user = userDetails.data.user_data;
  
        if (!user.is_staff && !user.is_superuser) {
          // Clear localStorage and prevent duplicate error
          localStorage.clear();
          setIsAuthenticated(false);
  
          // Show access denied error and stop further execution
          toast.error('Access denied: You do not have permission to log in.');
          return;
        }
  
        // Store tokens and set authentication
        localStorage.setItem('authToken', access);
        localStorage.setItem('refreshToken', refresh);
        toast.success('Login successful!');
        setIsAuthenticated(true);
  
        // Redirect to the dashboard
        navigate('/admin/dashboard', { replace: true });
      }
    } catch (err) {
      // Handle errors gracefully
      if (err.response && err.response.status === 403) {
        // Show access denied error
        toast.error('Access denied: You do not have permission to log in.');
      } else if (err.response) {
        // Handle other server-side errors (400, 500, etc.)
        const errorMsg = err.response.data?.error || 'Server error. Please try again later.';
        toast.error(errorMsg);
      } else {
        // Handle network errors or no response
        toast.error('Network error. Please try again later.');
      }
    } finally {
      setLoading(false);
      setShouldValidate(true);
    }
  };
  
  

  // Validate user role on component mount
  useEffect(() => {
    const validateRole = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) return; // Skip validation if no token is available
  
        const response = await api.get('api/check_login_status/', {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const user = response.data.user_data;
        if (!user.is_staff && !user.is_superuser) {
          localStorage.clear();
          setIsAuthenticated(false);
  
          // Show only this error if triggered from here
          toast.error('Unauthorized access. You have been logged out.');
          navigate('/auth/guidance-login', { replace: true });
        }
      } catch (err) {
        console.error('Role validation failed:', err);
        localStorage.clear();
        setIsAuthenticated(false);
        navigate('/auth/guidance-login', { replace: true });
      }
    };
  
    if (shouldValidate) { // Add this condition
      validateRole();
    }
  }, [shouldValidate, setIsAuthenticated, navigate]);
  
  
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Main login content */}
      <div className="container my-auto d-flex justify-content-center align-items-center">
        <div className="card shadow-lg p-4" style={{ maxWidth: '450px', width: '100%' }}>
          <div className="text-center">
            <div className="d-flex justify-content-center mb-3">
              <img src={Anna1} alt="Anna avatar" className="img-fluid rounded-circle" style={{ width: '150px' }} />
            </div>
            <h2 className="mb-4">Sign In</h2>
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading} // Disable input when loading
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading} // Disable input when loading
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-warning btn-block w-100"
              disabled={loading} // Disable button when loading
            >
              {loading ? 'Logging in...' : 'Login'} {/* Show loading state */}
            </button>
          </form>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-auto py-2 bg-warning text-center">
        <div className="container">
          <p className="mb-0">ANNA | Copyright © 2024</p>
        </div>
      </footer>
    </div>
  );
};

export default GuidanceLogin;
