// MyPagination.js
import React from "react";
import Pagination from "react-bootstrap/Pagination";

const MyPagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // If there's only one page, no need to show pagination
  if (totalPages <= 1) return null;

  const pageItems = [];

  if (currentPage > 3) {
    pageItems.push(
      <Pagination.Item
        key={1}
        active={currentPage === 1}
        onClick={() => onPageChange(1)}
      >
        1
      </Pagination.Item>
    );
  }

  if (currentPage > 4) {
    pageItems.push(<Pagination.Ellipsis key="leftEllipsis" />);
  }

  for (
    let page = Math.max(1, currentPage - 2);
    page <= Math.min(totalPages, currentPage + 2);
    page++
  ) {
    pageItems.push(
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => onPageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  if (currentPage < totalPages - 3) {
    pageItems.push(<Pagination.Ellipsis key="rightEllipsis" />);
  }

  if (currentPage < totalPages - 2) {
    pageItems.push(
      <Pagination.Item
        key={totalPages}
        active={currentPage === totalPages}
        onClick={() => onPageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        overflowX: "auto",
        margin: "0 auto",
      }}
    >
      <Pagination style={{ whiteSpace: "nowrap" }}>
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => onPageChange(1)}
        />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        />
        {pageItems}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        />
        <Pagination.Last
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(totalPages)}
        />
      </Pagination>
    </div>
  );
};

export default MyPagination;
