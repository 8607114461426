  import React, { useEffect, useState } from "react";
  import {
    Card,
    Button,
    Row,
    Col,
    Table,
    Container,
    Form,
    Alert,
    Modal,
    Spinner,
    Dropdown,
    DropdownButton,
    ButtonGroup 
  } from "react-bootstrap";
  import api from "../../utils/api"; // API utility to handle requests
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import * as XLSX from "xlsx";
  import { saveAs } from "file-saver";
  import ExcelJS from "exceljs";
  import Pagination from "react-bootstrap/Pagination";
  import GlobalDeadlineModal from "../../components/pages/GlobalDeadlineModal";
  import Papa from 'papaparse';
  import '../../styles/style.css';
  import { ProgressBar } from "react-bootstrap";

  const Management = () => {
    const [sections, setSections] = useState([]);
    const [newSectionName, setNewSectionName] = useState("");
    const [csvData, setCsvData] = useState([]); // Holds previewed CSV data

    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedSections, setSelectedSections] = useState([]); // For bulk actions
    const [students, setStudents] = useState([]); // For viewing students by section
    const [maxStarts, setMaxStarts] = useState({});
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [userSchoolId, setUserSchoolId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showStudentsModal, setShowStudentsModal] = useState(false);
    const [sectionToDelete, setSectionToDelete] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc"); // Default: ascending
    const [currentPage, setCurrentPage] = useState(1);
    const [studentsPerPage] = useState(10); // Number of students per page
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = students.slice(indexOfFirstStudent, indexOfLastStudent);
    const [showAllStudentsModal, setShowAllStudentsModal] = useState(false);
    const [allStudents, setAllStudents] = useState([]);
    const [allStudentsSearchQuery, setAllStudentsSearchQuery] = useState("");
    const [allStudentsDateFilter, setAllStudentsDateFilter] = useState("");
    const [loadingAllStudents, setLoadingAllStudents] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [addingSingleStudent, setAddingSingleStudent] = useState(false);

  // Sorting state for "All Students" table
    const [sortFieldAll, setSortFieldAll] = useState(null);
    const [sortOrderAll, setSortOrderAll] = useState("asc");
    const [previewCurrentPage, setPreviewCurrentPage] = useState(1);
    const [previewStudentsPerPage] = useState(10); // Adjust the number of students per page
    const indexOfLastPreviewStudent = previewCurrentPage * previewStudentsPerPage;
    const indexOfFirstPreviewStudent = indexOfLastPreviewStudent - previewStudentsPerPage;

    // Sorting state for "Section Students" table
    const [sortFieldSection, setSortFieldSection] = useState(null);
    const [sortOrderSection, setSortOrderSection] = useState("asc");
    const [resendLoading, setResendLoading] = useState({});
    const [sectionsCurrentPage, setSectionsCurrentPage] = useState(1);
    const sectionsPerPage = 10; // or any number you prefer
    const indexOfLastSection = sectionsCurrentPage * sectionsPerPage;
    const indexOfFirstSection = indexOfLastSection - sectionsPerPage;
    const currentSections = sections.slice(indexOfFirstSection, indexOfLastSection);
    const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false);
    const [showGlobalDeadlineModal, setShowGlobalDeadlineModal] = useState(false);
    const [globalDeadline, setGlobalDeadline] = useState("");
  // Add these near your other useState declarations
    const [selectedStudentsForBatchActions, setSelectedStudentsForBatchActions] = useState([]);
    const [showBatchExtendedDeadlineModal, setShowBatchExtendedDeadlineModal] = useState(false);
    const [newBatchExtendedDeadline, setNewBatchExtendedDeadline] = useState("");
    const [showBatchMaxUsageModal, setShowBatchMaxUsageModal] = useState(false);
    const [newBatchMaxUsage, setNewBatchMaxUsage] = useState("");
    const [batchResendLoading, setBatchResendLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); 
    const [showDeleteStudentModal, setShowDeleteStudentModal] = useState(false);
    const [studentToDelete, setStudentToDelete] = useState(null);
    const confirmDeleteStudent = (student) => {
      setStudentToDelete(student);
      setShowDeleteStudentModal(true);
    };
    const [showEditStudentModal, setShowEditStudentModal] = useState(false);
    const [studentToEdit, setStudentToEdit] = useState(null);
    const [editLoading, setEditLoading] = useState(false);
    const openEditStudentModal = (student) => {
      setStudentToEdit({ ...student }); // Clone to avoid mutation
      setShowEditStudentModal(true);
    };
   const handleResendEmailFromEdit = async () => {
  if (!studentToEdit?.id) return;

  try {
    setEditLoading(true);
    await api.put(`/users/${studentToEdit.id}/edit/`, studentToEdit);
    toast.success("Student details saved and activation email sent successfully.", { position: "top-right" });

    setAllStudents((prev) =>
      prev.map((s) => (s.id === studentToEdit.id ? studentToEdit : s))
    );

    await handleResendEmail(studentToEdit.id);
    setShowEditStudentModal(false);
  } catch (err) {
    toast.error("Failed to save before resending email.", { position: "top-right" });
  } finally {
    setEditLoading(false);
  }
};

    
        
    const [showExtendedDeadlineModal, setShowExtendedDeadlineModal] = useState(false);
    const [selectedStudentForDeadline, setSelectedStudentForDeadline] = useState(null);
    const [newExtendedDeadline, setNewExtendedDeadline] = useState("");
    const [csvSearchQuery, setCsvSearchQuery] = useState("");
    const filteredCsvData = csvData.filter(student =>
      student.id_no.toLowerCase().includes(csvSearchQuery.toLowerCase()) ||
      student.full_name.toLowerCase().includes(csvSearchQuery.toLowerCase()) ||
      student.email.toLowerCase().includes(csvSearchQuery.toLowerCase())
    );
    
    // Update paginated preview based on the filtered data:
    const paginatedPreviewStudents = filteredCsvData.slice(indexOfFirstPreviewStudent, indexOfLastPreviewStudent);
    const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);
    const [rowToRemove, setRowToRemove] = useState(null);
    const confirmRemoveCsvRow = (rowIndex) => {
      setRowToRemove(rowIndex);
      setShowRemoveConfirmModal(true);
    };
    
    const handleConfirmRemove = () => {
      if (rowToRemove !== null) {
        handleRemoveCsvRow(rowToRemove);
        setRowToRemove(null);
        setShowRemoveConfirmModal(false);
      }
    };
    // Function to open the modal and prefill the current extended deadline (if any)
    const openExtendedDeadlineModal = (student) => {
      setSelectedStudentForDeadline(student);
      setNewExtendedDeadline(student.extended_deadline || ""); // assuming ISO format
      setShowExtendedDeadlineModal(true);
    };

    // Function to update the extended deadline using the PATCH endpoint
    const handleSetExtendedDeadline = async () => {
      try {
        await api.patch(`/users/${selectedStudentForDeadline.id}/set-extended-deadline/`, {
          extended_deadline: newExtendedDeadline,
        });
        toast.success("Extended deadline updated successfully!", { position: "top-right" });
        // Optionally, update the student data in your state if needed.
        setShowExtendedDeadlineModal(false);
      } catch (err) {
        toast.error(err.response?.data?.error || "Error updating extended deadline.", { position: "top-right" });
      }
    };
    const handleBatchMaxChatbotUsage = async () => {
      try {
        await Promise.all(
          selectedStudentsForBatchActions.map(studentId =>
            api.patch(`/users/${studentId}/update-max-chatbot-starts/`, {
              max_chatbot_starts: newBatchMaxUsage,
            })
          )
        );
        toast.success("Max chatbot usage updated successfully for selected students!", { position: "top-right" });
        setSelectedStudentsForBatchActions([]);
        setShowBatchMaxUsageModal(false);
      } catch (error) {
        toast.error("Error updating max chatbot usage for selected students.", { position: "top-right" });
      }
    };
    
    // Add an "All Students" pagination if needed
    const [allStudentsCurrentPage, setAllStudentsCurrentPage] = useState(1);
    const [allStudentsPerPage] = useState(10);
    // For single-student add form
  // Existing states...
    const [showSingleStudentModal, setShowSingleStudentModal] = useState(false);
    const [singleStudent, setSingleStudent] = useState({
      id_no: "",
      full_name: "",
      email: "",
      gender: "",
      grade_level: "",
      section: "",
      strand: "",
    });

    // ADD THIS:
    const [validationErrors, setValidationErrors] = useState({
      id_no: "",
      full_name: "",
      email: "",
    });


    const [searchQuery, setSearchQuery] = useState("");
    const [selectedSectionName, setSelectedSectionName] = useState(""); // State for section name
    const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const [newStudent, setNewStudent] = useState({
    id_no: "",
    full_name: "",
    email: "",
    gender: "",
    grade_level: "",
    section: "",
    strand: "",
  });
    const [showCSVModal, setShowCSVModal] = useState(false); // To preview CSV contents

    const filteredStudents = students.filter((student) =>
      student.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      student.id_no.includes(searchQuery)
    );
    const handleOpenAllStudents = async () => {
      setShowAllStudentsModal(true);
      // Fetch all students from backend
      fetchAllStudents();
    };
    const openSingleStudentModal = () => {
      setSingleStudent({
        id_no: "",
        full_name: "",
        email: "",
        gender: "",
        grade_level: "",
        section: "",
        strand: "",
      });
      setShowSingleStudentModal(true);
    };
        
    const handleDeleteStudent = async () => {
      try {
        await api.delete(`/users/${studentToDelete.id}/delete/`);
        toast.success("Student deleted successfully!", { position: "top-right" });
        setAllStudents((prev) => prev.filter((s) => s.id !== studentToDelete.id));
        setShowDeleteStudentModal(false);
      } catch (err) {
        toast.error("Error deleting student.", { position: "top-right" });
      }
    };
    const fetchGlobalDeadline = async () => {
      try {
        const response = await api.get("/school/get-global-deadline/");
        if (response.data.global_deadline) {
          setGlobalDeadline(new Date(response.data.global_deadline));
        }
      } catch (error) {
        console.error("Error fetching global deadline", error);
      }
    };

    useEffect(() => {
      fetchGlobalDeadline();
    }, []);
    const handleSingleStudentChange = (e) => {
      setSingleStudent((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
    const handlePreviewPageChange = (newPage) => {
      setPreviewCurrentPage(newPage);
    };
    const handleEditStudentChange = (e) => {
      const { name, value } = e.target;
      setStudentToEdit((prev) => ({ ...prev, [name]: value }));
    };
    const [editValidationErrors, setEditValidationErrors] = useState({
      id_no: "",
      full_name: "",
      email: "",
    });
    const handleSaveEditedStudent = async () => {
      if (!studentToEdit) return;
    
      setEditValidationErrors({
        id_no: "",
        full_name: "",
        email: "",
      });
    
      try {
        setEditLoading(true);
        const response = await api.put(`/users/${studentToEdit.id}/edit/`, studentToEdit);
        toast.success("Student updated successfully.", { position: "top-right" });
    
        setAllStudents((prev) =>
          prev.map((s) => (s.id === studentToEdit.id ? studentToEdit : s))
        );
    
        setShowEditStudentModal(false);
      } catch (err) {
        if (err.response?.data?.errors) {
          setEditValidationErrors(err.response.data.errors);
          Object.values(err.response.data.errors).forEach((msg) =>
            toast.error(msg, { position: "top-right" })
          );
        } else {
          toast.error("Error saving student updates.", { position: "top-right" });
        }
      } finally {
        setEditLoading(false);
      }
    };
    
    
    
    // Dropdown options for CSV preview editing
  const genderOptions = ["Male", "Female"];
  const gradeLevelOptions = ["11", "12"];
  const strandOptions = [
    "ABM",
    "ARTS & DESIGN",
    "STEM",
    "HUMSS",
    "TVL - Information and Communications Technology",
    "TVL - Home Economics",
    "TVL - Agri-Fishery Arts",
    "TVL - Industrial Arts",
  ];
  const handleBatchExtendedDeadline = async () => {
    try {
      await Promise.all(
        selectedStudentsForDeadline.map(studentId =>
          api.patch(`/users/${studentId}/set-extended-deadline/`, {
            extended_deadline: newBatchExtendedDeadline,
          })
        )
      );
      toast.success("Extended deadline updated successfully for selected students!", { position: "top-right" });
      // Clear selection and close the modal
      setSelectedStudentsForDeadline([]);
      setShowBatchExtendedDeadlineModal(false);
    } catch (error) {
      toast.error("Error updating extended deadline for selected students.", { position: "top-right" });
    }
  };
  const handleResendInactive = async () => {
    // Filter out all inactive users
    const inactiveIds = allStudents
      .filter((student) => !student.is_active)  // only inactive
      .map((student) => student.id);

    if (inactiveIds.length === 0) {
      toast.info("No inactive users found.", { position: "top-right" });
      return;
    }

    try {
      setBatchResendLoading(true);
      // Call the batch endpoint
      await api.post("/users/batch-resend-activation-email/", {
        user_ids: inactiveIds,
      });
      toast.success("Activation emails resent to all inactive users!", { position: "top-right" });
    } catch (error) {
      toast.error("Error resending emails to inactive users.", { position: "top-right" });
    } finally {
      setBatchResendLoading(false);
    }
  };


  // Handler to update a specific field of a CSV row
  const handleCsvRowChange = (rowIndex, field, value) => {
    setCsvData((prevData) =>
      prevData.map((student, index) =>
        index === rowIndex ? { ...student, [field]: value } : student
      )
    );
  };

  // Handler to remove a row from the CSV preview
  const handleRemoveCsvRow = (rowIndex) => {
    setCsvData((prevData) => prevData.filter((_, index) => index !== rowIndex));
  };

  const handleAddSingleStudent = async () => {
    setValidationErrors({
      id_no: "",
      full_name: "",
      email: "",
    });
  
    if (!singleStudent.id_no || !singleStudent.full_name || !singleStudent.email) {
      toast.error("ID, full name, and email are required.", { position: "top-right" });
      return;
    }
  
    setAddingSingleStudent(true); // Start loading
  
    try {
      const checkResponse = await api.post("/users/check-duplicate/", {
        id_no: singleStudent.id_no,
        full_name: singleStudent.full_name,
        email: singleStudent.email,
      });
  
      if (checkResponse.data?.errors) {
        setValidationErrors(checkResponse.data.errors);
        Object.values(checkResponse.data.errors).forEach((msg) =>
          toast.error(msg, { position: "top-right" })
        );
        return;
      }
  
      const payload = {
        id_no: singleStudent.id_no,
        full_name: singleStudent.full_name,
        email: singleStudent.email,
        sex: singleStudent.gender,
        strand: singleStudent.strand,
        grade_level: singleStudent.grade_level,
        school_id: userSchoolId,
        section_id: singleStudent.section,
        walkIn: "no",
      };
  
      const addResponse = await api.post("/register/", payload);
      fetchAllStudents();
      toast.success("Student added successfully!", { position: "top-right" });
      setShowSingleStudentModal(false);
    } catch (err) {
      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
        Object.values(err.response.data.errors).forEach((msg) =>
          toast.error(msg, { position: "top-right" })
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message, { position: "top-right" });
      } else {
        toast.error("Error adding student. Please try again.", { position: "top-right" });
      }
    } finally {
      setAddingSingleStudent(false); // End loading
    }
  };
  

    

    
    
      // NEW: Function to fetch all students
      const fetchAllStudents = async () => {
        setLoadingAllStudents(true);
        try {
          const response = await api.get("/users/all-students/"); 
          // ^ you'll create an endpoint like /users/all-students
          setAllStudents(response.data); 
        } catch (err) {
          toast.error("Error fetching all students.", { position: "top-right" });
        } finally {
          setLoadingAllStudents(false);
        }
      };
      // NEW: Filter logic for All Students
    // Use separate state for All Students modal pagination
  // (These are already declared in your code)
  // const [allStudentsCurrentPage, setAllStudentsCurrentPage] = useState(1);
  // const [allStudentsPerPage] = useState(10);

  // Compute filteredAllStudents from the "allStudents" state
  const query = allStudentsSearchQuery.toLowerCase();

  const filteredAllStudents = allStudents.filter(student => {
    const matchSearch =
      student.full_name.toLowerCase().includes(query) ||
      student.id_no.toLowerCase().includes(query) ||
      student.email.toLowerCase().includes(query);
  
    let matchDate = true;
    if (allStudentsDateFilter) {
      const studentDate = new Date(student.date_added)
        .toLocaleDateString('en-CA');
      matchDate = studentDate === allStudentsDateFilter;
    }
  
    return matchSearch && matchDate;
  });
  

  // Compute paginatedAllStudents using the All Students modal pagination state
  const indexOfLastAllStudent = allStudentsCurrentPage * allStudentsPerPage;
  const indexOfFirstAllStudent = indexOfLastAllStudent - allStudentsPerPage;
  const paginatedAllStudents = filteredAllStudents.slice(indexOfFirstAllStudent, indexOfLastAllStudent);

    const [sectionNames, setSectionNames] = useState([""]); // Array of section names
    const addNewSectionField = () => {
      setSectionNames([...sectionNames, ""]);
    };
    // Handle changes in input fields
  const handleSectionNameChange = (e, index) => {
    const updatedSections = [...sectionNames];
    updatedSections[index] = e.target.value;
    setSectionNames(updatedSections);
  };
  // Remove a section input field
  const removeSection = (index) => {
    setSectionNames(sectionNames.filter((_, i) => i !== index));
  };
    // Handler for pagination
    const handleAllStudentsPageChange = (newPage) => {
      setAllStudentsCurrentPage(newPage);
    };

    // (B) Resend Email function
    const handleResendEmail = async (userId) => {
      setResendLoading((prev) => ({ ...prev, [userId]: true })); // Set loading for specific user
      
      try {
        await api.post(`/users/${userId}/resend-activation-email/`);
        toast.success("Activation email resent successfully!", { position: "top-right" });
      } catch (err) {
        toast.error(
          err.response?.data?.message || "An error occurred. Please try again.",
          { position: "top-right" }
        );
      } finally {
        setResendLoading((prev) => ({ ...prev, [userId]: false })); // Reset loading state
      }
    };
    
    

  
    // Paginate the filtered results
    const paginatedStudents = filteredStudents.slice(
      (currentPage - 1) * studentsPerPage,
      currentPage * studentsPerPage
    );
  // Inside your Management component, add this state:

  const confirmCloseCSV = () => {
    // Clear CSV data and file
    setCsvData([]);
    setCsvFile(null);
    // Reset the file input element (if applicable)
    const fileInput = document.getElementById("fileInput");
    if (fileInput) fileInput.value = "";
    // Close the CSV modal and the confirmation modal
    setShowCSVModal(false);
    setShowCloseConfirmModal(false);
  };

    
  
    
    const handleCloseStudentsModal = () => {
      setCurrentPage(1); // Reset to the first page
      setShowStudentsModal(false); // Close the modal
    };
    
    // Add this function
    const handleSort = (field) => {
      const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
      setSortField(field);
      setSortOrder(order);
    
      if (!sections || sections.length === 0) {
        console.error("No data to sort");
        return;
      }
    
      const sortedSections = [...sections].sort((a, b) => {
        if (typeof a[field] === "string" && typeof b[field] === "string") {
          return order === "asc"
            ? a[field].localeCompare(b[field])
            : b[field].localeCompare(a[field]);
        }
        if (a[field] < b[field]) return order === "asc" ? -1 : 1;
        if (a[field] > b[field]) return order === "asc" ? 1 : -1;
        return 0;
      });
    
      setSections(sortedSections);
    };
    
    
    useEffect(() => {
      const fetchSchoolAndSections = async () => {
        setLoading(true); // Show loading spinner
        try {
          const userResponse = await api.get("/auth/user");
          const schoolId = userResponse.data.school_id;
          setUserSchoolId(schoolId);
    
          const response = await api.get(`api/sections?school_id=${schoolId}`);
          setSections(response.data);
        } catch (err) {
          toast.error("Error fetching sections.", { position: "top-right" });
        } finally {
          setLoading(false); // Hide loading spinner
        }
      };
    
      fetchSchoolAndSections();
    }, []);
    const handleAddManualStudent = () => {
      if (!newStudent.id_no || !newStudent.full_name || !newStudent.email) {
        toast.error("ID number, full name, and email are required.", { position: "top-right" });
        return;
      }
    
      setCsvData(prevData => [...prevData, newStudent]);
      setNewStudent({
        id_no: "",
        full_name: "",
        email: "",
        gender: "",
        grade_level: "",
        section: "",
        strand: "",
      });
      toast.success("Student added successfully!", { position: "top-right" });
    };
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (!file) {
        setCsvFile(null);
        return;
      }
      setCsvFile(file);
    
      // Determine file extension
      const fileExtension = file.name.split('.').pop().toLowerCase();
    
      if (fileExtension === "xlsx") {
        // Use FileReader to read as array buffer and convert XLSX to CSV string
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          // Assume the first sheet contains the data
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
          parseCSVData(csvOutput);
        };
        reader.readAsArrayBuffer(file);
      } else {
        // For CSV files, read as text directly
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target.result;
          parseCSVData(text);
        };
        reader.readAsText(file);
      }
    };

  const parseCSVData = (csvText) => {
    // Parse CSV text using PapaParse with headers enabled and skipping empty lines.
    const results = Papa.parse(csvText, {
      header: true,
      skipEmptyLines: true,
    });

    // Define a mapping from CSV header to internal property names.
    const headerMap = {
      "id number": "id_no",
      "full name": "full_name",
      "email": "email",
      "gender": "gender",
      "grade level": "grade_level",
      "section": "section",
      "strand": "strand"
    };

    // Convert each row object using the headerMap.
    const data = results.data.map((row) => {
      let obj = {};
      Object.keys(row).forEach((key) => {
        const trimmedKey = key.trim().toLowerCase();
        const newKey = headerMap[trimmedKey] || trimmedKey;
        obj[newKey] = row[key] ? row[key].trim() : "";
      });
      // Add an initial emailStatus property.
      obj.emailStatus = "pending";
      return obj;
    }).filter(student => student.id_no); // Filter out rows with no id_no.

    setCsvData(data);
    setShowCSVModal(true);
  };

    
    
  const handleUploadCSV = async () => {
    if (!csvFile) {
      console.error("No file selected.");
      toast.error("Please select a file to upload.", { position: "top-right" });
      return;
    }
  
    // Reset progress to 0% and set your uploading flag
    setUploadProgress(0);
    setUploading(true);
  
    // Mark all rows in preview as "loading"
    setCsvData((prevData) =>
      prevData.map((student) => ({ ...student, emailStatus: "loading" }))
    );
  
    // Function to send FormData to the server
    const uploadFormData = async (formData) => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("Error: No authentication token found.");
        toast.error("Authentication error. Please log in again.", { position: "top-right" });
        return;
      }
  
      try {
        // Here’s where we add onUploadProgress:
        const response = await api.post("/students/upload_nonwalkin/", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // progressEvent.loaded & progressEvent.total are in bytes
            if (progressEvent.total) {
              const percent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percent);
            }
          },
        });
  
        console.log("Response from API:", response.data);
  
        if (response.data.created_users.length > 0) {
          toast.success(
            `${response.data.created_users.length} students added successfully.`,
            { position: "top-right" }
          );
        }
  
        if (response.data.errors.length > 0) {
          response.data.errors.forEach((error) =>
            toast.warning(error, { position: "top-right" })
          );
        }
  
        // Mark processed rows as "sent"
        const updatedData = csvData.map((student) => {
          const created = response.data.created_users.find(
            (u) => u.email === student.email
          );
          return created ? { ...student, emailStatus: "sent" } : student;
        });
        setCsvData(updatedData);
      } catch (err) {
        console.error(
          "Error uploading CSV:",
          err.response?.data || err.message
        );
        toast.error(
          "Error uploading CSV. Please check the file format.",
          { position: "top-right" }
        );
      } finally {
        // Reset uploading & progress
        setUploading(false);
        setUploadProgress(0);
        setCsvFile(null);
      }
    };
  
    // Determine whether the file is .xlsx or .csv
    const fileExtension = csvFile.name.split(".").pop().toLowerCase();
  
    if (fileExtension === "xlsx") {
      // If XLSX, convert to CSV first
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
  
        const csvBlob = new Blob([csvOutput], {
          type: "text/csv;charset=utf-8;",
        });
  
        const formData = new FormData();
        formData.append("file", csvBlob, "converted.csv");
        await uploadFormData(formData);
      };
      reader.readAsArrayBuffer(csvFile);
    } else {
      // If CSV, upload as-is
      const reader = new FileReader();
      reader.onload = async (event) => {
        const text = event.target.result;
        const formData = new FormData();
        formData.append(
          "file",
          new Blob([text], { type: "text/csv;charset=utf-8;" }),
          csvFile.name
        );
        await uploadFormData(formData);
      };
      reader.readAsText(csvFile);
    }
  };
  

  const handleDownloadTemplate = async () => {
    if (!sections || sections.length === 0) {
      toast.error("No sections available. Please add sections first.");
      return;
    }

    // Fetch sections dynamically
    const availableSections = sections.map((section) => section.name);

    // Predefined strands
    const availableStrands = [
      "ABM",
      "ARTS & DESIGN",
      "STEM",
      "HUMSS",
      "TVL - Information and Communications Technology",
      "TVL - Home Economics",
      "TVL - Agri-Fishery Arts",
      "TVL - Industrial Arts",
    ];

    // Define available options
    const gradeLevels = [11, 12];
    const genderOptions = ["Male", "Female"];

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Student Template");

    // Add column headers with styles
    worksheet.columns = [
      { header: "ID Number", key: "id_no", width: 15 },
      { header: "Full Name", key: "full_name", width: 25 },
      { header: "Email", key: "email", width: 30 },
      { header: "Gender", key: "gender", width: 12 },
      { header: "Grade Level", key: "grade_level", width: 12 },
      { header: "Section", key: "section", width: 20 },
      { header: "Strand", key: "strand", width: 25 },
    ];

    // Style header row
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F9C846" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add empty rows from row 2 to row 1000
    for (let i = 2; i <= 1000; i++) {
      worksheet.addRow(["", "", "", "", "", "", ""]);
    }

    // Define names for the dropdown ranges using the definedNames.add API
    workbook.definedNames.add("Gender_List", '"Male,Female"');
    workbook.definedNames.add("Grade_Level_List", '"11,12"');
    workbook.definedNames.add("Section_List", `"${availableSections.join(",")}"`);
    workbook.definedNames.add("Strand_List", `"${availableStrands.join(",")}"`);

    // Apply data validation for rows 2 to 1000
    for (let i = 2; i <= 1000; i++) {
      // Gender dropdown (Column D)
      worksheet.getCell(`D${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"Male,Female"'],
      };

      // Grade Level dropdown (Column E)
      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"11,12"'],
      };

      // Section dropdown (Column F)
      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${availableSections.join(",")}"`],
      };

      // Strand dropdown (Column G)
      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${availableStrands.join(",")}"`],
      };
    }

    // Ensure Grade Level is stored as a number
    worksheet.getColumn(5).numFmt = "0";

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const file = new Blob(
      [buffer],
      { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
    );
    saveAs(file, "student_template.xlsx");
  };


  // Add this function here
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1); // Reset to the first page
    };

    const handleAddSection = async () => {
      // Remove empty or duplicate section names
      const trimmedSections = sectionNames
        .map(name => name.trim()) // Trim spaces
        .filter((name, index, self) => name && self.indexOf(name) === index); // Remove empty & duplicate names
    
      if (trimmedSections.length === 0) {
        toast.error("At least one valid section name is required.", { position: "top-right" });
        return;
      }
    
      // Check for existing sections
      const existingSections = sections.map(section => section.name.toLowerCase());
      const duplicateSections = trimmedSections.filter(name => existingSections.includes(name.toLowerCase()));
    
      if (duplicateSections.length > 0) {
        toast.error(`Sections already exist: ${duplicateSections.join(", ")}`, { position: "top-right" });
        return;
      }
    
      setLoading(true);
      try {
        const response = await api.post("/sections/add/", {
          sections: trimmedSections, // Send array of section names
          school_id: userSchoolId,
        });
    
        // Update the state with new sections
        setSections(prevSections => [...prevSections, ...response.data]);
        setSectionNames([""]); // Reset input fields
        toast.success(`${response.data.length} section(s) added successfully!`, { position: "top-right" });
        setShowAddModal(false);
      } catch (err) {
        toast.error(err.response?.data?.error || "Error adding new section(s).", { position: "top-right" });
      } finally {
        setLoading(false);
      }
    };
    
const handleExportCSV = () => {
  // Use the array you want to export, e.g. filteredAllStudents
  const exportData = filteredAllStudents;
  if (!exportData || exportData.length === 0) {
    toast.warning("No students to export.", { position: "top-right" });
    return;
  }

  let csvContent = "data:text/csv;charset=utf-8,";
  // CSV headers - added the new column for RIASEC recommendations
  csvContent += ["id_no", "full_name", "email", "strand", "section", "riasec_course_recommendations"].join(",") + "\n";

  exportData.forEach((stud) => {
    const row = [
      `'${stud.id_no}'`,    // Single quote to avoid scientific notation
      `"${stud.full_name}"`,
      `"${stud.email}"`,
      `"${stud.strand || ""}"`,
      `"${stud.section_name}"`,
      `"${stud.riasec_course_recommendations || ""}"`,
    ].join(",");
    csvContent += row + "\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "all_students.csv");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

    
    

    const handleEditSection = (section) => {
      setSelectedSection(section);
      setShowModal(true);
    };

    const handleSaveEdit = async () => {
      if (!selectedSection || !selectedSection.name) {
        toast.error("Section name cannot be empty.", { position: "top-right" });
        return;
      }
      setLoading(true);
    
      try {
        const response = await api.put(`/sections/${selectedSection.id}/`, {
          name: selectedSection.name,
          school_id: userSchoolId,
        });
    
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === response.data.id ? response.data : section
          )
        );
    
        toast.success("Section updated successfully.", { position: "top-right" });
        setShowModal(false);
      } catch (err) {
        toast.error("Error updating section.", { position: "top-right" });
      } finally {
        setLoading(false);
      }
    };
    
    

    const handleDeleteConfirmation = (section) => {
      setSectionToDelete(section);
      setShowDeleteModal(true);
    };

    const handleDeleteSection = async () => {
      setDeleting(true);
    
      try {
        await api.delete(`/sections/${sectionToDelete.id}/delete/`);
        setSections((prevSections) =>
          prevSections.filter((section) => section.id !== sectionToDelete.id)
        );
    
        toast.success("Section deleted successfully.", { position: "top-right" });
        setShowDeleteModal(false);
      } catch (err) {
        toast.error("Error deleting section.", { position: "top-right" });
      } finally {
        setDeleting(false);
      }
    };
    

    const handleSelectSection = (sectionId) => {
      setSelectedSections((prevSelected) =>
        prevSelected.includes(sectionId)
          ? prevSelected.filter((id) => id !== sectionId)
          : [...prevSelected, sectionId]
      );
    };

    const handleSelectAll = () => {
      if (selectedSections.length === sections.length) {
        setSelectedSections([]);
      } else {
        setSelectedSections(sections.map((section) => section.id));
      }
    };
    const handleToggleActive = async (userId, currentStatus) => {
      try {
        await api.patch(`/users/${userId}/activate/`, {
          is_active: !currentStatus, // Toggle status
        });
    
        // ✅ Update real-time in both `allStudents` and `students`
        setAllStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === userId ? { ...student, is_active: !currentStatus } : student
          )
        );
    
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === userId ? { ...student, is_active: !currentStatus } : student
          )
        );
    
        toast.success(
          `User has been ${!currentStatus ? "activated" : "deactivated"} successfully.`,
          { position: "top-right" }
        );
      } catch (err) {
        toast.error("Error updating active status.", { position: "top-right" });
      }
    };
    
    
    const handleBulkDelete = async () => {
      if (selectedSections.length === 0) {
        toast.error("No sections selected for deletion.", { position: "top-right" });
        return;
      }
    
      setDeleting(true);
    
      try {
        const response = await api.post("/sections/bulk-delete/", {
          ids: selectedSections,
        });
    
        setSections((prevSections) =>
          prevSections.filter((section) => !selectedSections.includes(section.id))
        );
        setSelectedSections([]); // Clear selection
        toast.success("Selected sections deleted successfully.", { position: "top-right" });
      } catch (err) {
        toast.error("Error deleting selected sections.", { position: "top-right" });
      } finally {
        setDeleting(false);
      }
    };
    
    
    
    const handleUpdateMaxStarts = async (userId) => {
      const newMaxStarts = maxStarts[userId];
    
      if (!newMaxStarts || parseInt(newMaxStarts) <= 0) {
        toast.error("Invalid max starts value.", { position: "top-right" });
        return;
      }
    
      try {
        await api.patch(`/users/${userId}/update-max-chatbot-starts/`, {
          max_chatbot_starts: newMaxStarts,
        });
    
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === userId
              ? { ...student, max_chatbot_starts: newMaxStarts }
              : student
          )
        );
    
        toast.success("Max chatbot starts updated successfully.", { position: "top-right" });
      } catch (err) {
        toast.error("Error updating max chatbot starts.", { position: "top-right" });
      }
    };
    
    // For the "All Students" modal
  const handleSortAllStudents = (field) => {
    const order = sortFieldAll === field && sortOrderAll === "asc" ? "desc" : "asc";
    setSortFieldAll(field);
    setSortOrderAll(order);

    // Use filteredAllStudents, which is your currently displayed "All Students".
    const sorted = [...filteredAllStudents].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      }
      return order === "asc" ? a[field] - b[field] : b[field] - a[field];
    });

    // Update the main allStudents state so the sorted data appears
    setAllStudents(sorted);
    // Reset pagination to page 1
    setAllStudentsCurrentPage(1);
  };

  // For the "View Students in a Section" modal
  const handleSortSectionStudents = (field) => {
    const order = sortFieldSection === field && sortOrderSection === "asc" ? "desc" : "asc";
    setSortFieldSection(field);
    setSortOrderSection(order);

    // Use filteredStudents or students, depending on how you display them
    const sorted = [...filteredStudents].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      }
      return order === "asc" ? a[field] - b[field] : b[field] - a[field];
    });

    // Update the main students state so the sorted data appears
    setStudents(sorted);
    // Reset pagination to page 1
    setCurrentPage(1);
  };

    
    
    const handleSortSections = (key) => {
      const sortedSections = [...sections].sort((a, b) =>
        a[key] > b[key] ? 1 : -1
      );
      setSections(sortedSections);
    };


    const handleViewStudents = async (sectionId) => {
      setLoading(true);
      try {
        const section = sections.find((sec) => sec.id === sectionId);
        setSelectedSectionName(section?.name || ""); // Set the section name for the modal title
    
        const response = await api.get(`/sections/${sectionId}/students/`);
        const sortedStudents = response.data
          .map((student) => ({
            ...student,
            has_taken_test: student.has_taken_test, // True if test is taken
            test_date: student.test_date
              ? new Date(student.test_date).toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : "Not Started",
          }))
          .sort((a, b) => {
            if (a.has_taken_test && !b.has_taken_test) return -1; // "Taken" first
            if (!a.has_taken_test && b.has_taken_test) return 1;
            return 0; // Maintain order within groups
          });
        setStudents(sortedStudents);
        setShowStudentsModal(true);
      } catch (err) {
        toast.error("Error fetching students for this section.", {
          position: "top-right",
        });
      } finally {
        setLoading(false);
      }
    };
    
    

    if (loading) {
      return (
        <div className="text-center mt-5">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <p>Loading sections...</p>
        </div>
      );
    }
    
    if (!sections || sections.length === 0) {
      return (
        <div className="text-center mt-5">
          <Alert variant="info">No sections available for your school.</Alert>
        </div>
      );
    }
  // Handle Bulk Delete Confirmation
  const confirmBulkDelete = () => {
    if (selectedSections.length === 0) {
      toast.error("No sections selected for deletion.", { position: "top-right" });
      return;
    }
    setShowBulkDeleteModal(true);
  };

  // Handle Confirmed Bulk Delete
  const handleConfirmedBulkDelete = async () => {
    setDeleting(true);
    try {
      await api.post("/sections/bulk-delete/", { ids: selectedSections });

      setSections((prevSections) =>
        prevSections.filter((section) => !selectedSections.includes(section.id))
      );
      setSelectedSections([]); // Clear selection
      toast.success("Selected sections deleted successfully.", { position: "top-right" });
      setShowBulkDeleteModal(false);
    } catch (err) {
      toast.error("Error deleting selected sections.", { position: "top-right" });
    } finally {
      setDeleting(false);
    }
  };

  // CHOOSE matching param names:
  const MyPagination = ({
    currentPage,
    totalSections,   // total items (e.g., students)
    sectionsPerPage, // items per page
    onPageChange
  }) => {
    // Calculate the total number of pages
    const totalPages = Math.ceil(totalSections / sectionsPerPage);

    // If there's only one page, no need to show pagination
    if (totalPages <= 1) return null;

    // Create an array to hold the page items
    const pageItems = [];

    // 1) Always show page "1" if we're far from the beginning
    if (currentPage > 3) {
      pageItems.push(
        <Pagination.Item
          key={1}
          active={currentPage === 1}
          onClick={() => onPageChange(1)}
        >
          1
        </Pagination.Item>
      );
    }

    // 2) Add left ellipsis if there's a gap between page 1 and the current window
    if (currentPage > 4) {
      pageItems.push(<Pagination.Ellipsis key="leftEllipsis" />);
    }

    // 3) Render a window of pages around the current page (currentPage - 2 to currentPage + 2)
    for (
      let page = Math.max(1, currentPage - 2);
      page <= Math.min(totalPages, currentPage + 2);
      page++
    ) {
      pageItems.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    // 4) Add right ellipsis if there's a gap between the current window and the last page
    if (currentPage < totalPages - 3) {
      pageItems.push(<Pagination.Ellipsis key="rightEllipsis" />);
    }

    // 5) Always show the last page if we're not close to it already
    if (currentPage < totalPages - 2) {
      pageItems.push(
        <Pagination.Item
          key={totalPages}
          active={currentPage === totalPages}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return (
      <div className="d-flex justify-content-center" style={{ overflowX: "auto" }}>
        <Pagination style={{ whiteSpace: "nowrap" }}>
          <Pagination.First
            disabled={currentPage === 1}
            onClick={() => onPageChange(1)}
          />
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          />
          {pageItems}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          />
          <Pagination.Last
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(totalPages)}
          />
        </Pagination>
      </div>
    );
  };




    return (
      
      <Container className="my-4">
        <h2 className="text-center mb-4 fw-bold">Management Dashboard</h2>

        {/* Bulk Actions */}
        <Row className="mb-3 d-flex align-items-center justify-content-between">
    <Col className="d-flex gap-2">
      <Button variant="success" onClick={() => setShowAddModal(true)}>
        + Add Section
      </Button>

      {selectedSections.length > 0 && (
        <Button
          variant="danger"
          onClick={confirmBulkDelete}
          disabled={deleting}
        >
          {deleting ? <Spinner animation="border" size="sm" /> : "Delete Selected"}
        </Button>
      )}
    </Col>
    <GlobalDeadlineModal
    show={showGlobalDeadlineModal}
    onHide={() => setShowGlobalDeadlineModal(false)}
    currentDeadline={globalDeadline}
  />
    {/* Right-align the View All Students button */}
    <Col className="d-flex justify-content-end">
      <Button
        onClick={handleOpenAllStudents}
        style={{
          background: "linear-gradient(135deg, #007bff, #0056b3)", 
          color: "#fff",
          fontWeight: "bold",
          fontSize: "1rem",
          padding: "10px 15px",
          borderRadius: "6px",
          boxShadow: "0px 3px 8px rgba(0, 123, 255, 0.3)",
          transition: "0.3s",
          border: "none",
        }}
        onMouseEnter={(e) => (e.target.style.opacity = "0.85")}
        onMouseLeave={(e) => (e.target.style.opacity = "1")}
      >
        <i className="bi bi-people-fill me-2"></i> View All Students
      </Button>
    </Col>
  </Row>


  <Row className="mb-3">
    <Col md={6}>
    <Form.Group controlId="csvUpload">
    <Form.Label><strong>Batch Student Registration (CSV)</strong></Form.Label>
    <div className="d-flex align-items-center">
      <div className="custom-file w-50">
      <Form.Control
    type="file"
    id="fileInput"
    accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    onChange={handleFileChange}
    style={{ display: "none" }}
  />

        <label
          htmlFor="fileInput"
          className="btn btn-outline-primary w-100 d-flex justify-content-between align-items-center"
          style={{
            padding: "10px",
            borderRadius: "6px",
            fontWeight: "bold",
            border: "2px solid #007bff",
            cursor: "pointer",
          }}
        >
          <span>{csvFile ? csvFile.name : "Choose File"}</span>
          <i className="bi bi-upload"></i>
        </label>
      </div>
  {/* Download Template Button */}
  <Button
    variant="success"
    onClick={handleDownloadTemplate}
    className="ms-2 d-flex align-items-center justify-content-center position-relative"
    style={{
      background: "#217346",
      padding: "10px",
      borderRadius: "6px",
      border: "none",
      boxShadow: "0px 3px 6px rgba(33, 115, 70, 0.3)",
      transition: "width 0.3s ease, opacity 0.2s ease",
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.width = "160px"; // Expand width
      e.currentTarget.querySelector(".icon").style.opacity = "0"; // Hide icon
      e.currentTarget.querySelector(".text").style.opacity = "1"; // Show text
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.width = "40px"; // Shrink back
      e.currentTarget.querySelector(".icon").style.opacity = "1"; // Show icon
      e.currentTarget.querySelector(".text").style.opacity = "0"; // Hide text
    }}
  >
    {/* Icon - Visible by default */}
    <i className="bi bi-file-earmark-excel icon" 
      style={{ fontSize: "1.5rem", color: "white", transition: "opacity 0.2s ease" }}>
    </i>

    {/* Text - Hidden by default, appears on hover */}
    <span 
      className="text" 
      style={{
        color: "white",
        fontSize: "0.9rem",
        fontWeight: "bold",
        position: "absolute",
        left: "15px",
        opacity: 0, // Initially hidden
        transition: "opacity 0.2s ease",
      }}
    >
      Download Template
    </span>
  </Button>

    </div>
  </Form.Group>

    </Col>
  </Row>



        {/* Manage Sections */}
        <Row>
    <Col md={12}>
    <Card 
    className="mb-4" 
    style={{
      margin: "0 auto", // Center the card
      width: "fit-content", // Adjust card width to fit table content
      padding: "0", // Remove extra padding
      border: "none", // Remove any default border
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for better visibility
    }}
  >
    <Card.Header
      className="text-center"
      style={{
        backgroundColor: "#f9c846", // Soft gold background for better contrast
        fontWeight: "bold",
        fontSize: "22px", // Slightly larger font size
        padding: "20px", // Increased padding for a more balanced look
        borderBottom: "2px solid #e5a700", // Add a border for visual separation
      }}
    >
      <span style={{ textShadow: "1px 1px 2px rgba(0,0,0,0.1)" }}>
        Manage Sections
      </span>
    </Card.Header>

    <Card.Body style={{ padding: "0" }}>
      <div
        style={{
          overflowX: "auto", // Allow horizontal scrolling for smaller screens
          backgroundColor: "#ffffff", // Ensure a clean white background
          borderRadius: "5px", // Add slight rounding to corners
        }}
      >
        <Table
          striped
          bordered
          hover
          responsive
          style={{
            margin: "0", // Align the table with the card
            borderCollapse: "collapse", // Ensure proper table structure
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Subtle shadow for depth
          }}
        >
          <thead
            style={{
              backgroundColor: "#f5f5f5", // Light gray for header background
              color: "#333", // Darker text for readability
              fontWeight: "bold", // Bold font for header text
            }}
          >
            <tr>
              <th className="text-center">
                <Form.Check
                  type="checkbox"
                  checked={selectedSections.length === sections.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th className="text-center">#</th>
              <th
                className="text-start"
                onClick={() => handleSort("name")}
                style={{ cursor: "pointer" }}
              >
                Section Name{" "}
                <i
                  className={`bi ${
                    sortField === "name"
                      ? sortOrder === "asc"
                        ? "bi-arrow-up"
                        : "bi-arrow-down"
                      : "bi-arrow-down-up"
                  }`}
                  style={{
                    color: sortField === "name" ? "#007bff" : "inherit", // Highlight active column
                  }}
                ></i>
              </th>
              <th
                className="text-center"
                onClick={() => handleSort("student_count")}
                style={{ cursor: "pointer" }}
              >
                Student Count{" "}
                <i
                  className={`bi ${
                    sortField === "student_count"
                      ? sortOrder === "asc"
                        ? "bi-arrow-up"
                        : "bi-arrow-down"
                      : "bi-arrow-down-up"
                  }`}
                  style={{
                    color: sortField === "student_count" ? "#007bff" : "inherit",
                  }}
                ></i>
              </th>
              <th
                className="text-center"
                onClick={() => handleSort("created_at")}
                style={{ cursor: "pointer" }}
              >
                Date Created{" "}
                <i
                  className={`bi ${
                    sortField === "created_at"
                      ? sortOrder === "asc"
                        ? "bi-arrow-up"
                        : "bi-arrow-down"
                      : "bi-arrow-down-up"
                  }`}
                  style={{
                    color: sortField === "created_at" ? "#007bff" : "inherit",
                  }}
                ></i>
              </th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
    {currentSections.length > 0 ? (
      currentSections.map((section, index) => (
        <tr key={section.id}>
          <td>
            <Form.Check
              type="checkbox"
              checked={selectedSections.includes(section.id)}
              onChange={() => handleSelectSection(section.id)}
            />
          </td>
          <td>{indexOfFirstSection + index + 1}</td>
          <td>{section.name}</td>
          <td>{section.student_count}</td>
          <td>
            {section.created_at
              ? new Date(section.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : "N/A"}
          </td>
          <td className="text-center">
            <div className="d-flex justify-content-center">
              <Button
                variant="info"
                size="sm"
                className="me-2"
                onClick={() => handleViewStudents(section.id)}
              >
                View Students
              </Button>
              <Button
                variant="warning"
                size="sm"
                className="me-2"
                onClick={() => handleEditSection(section)}
              >
                Edit
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDeleteConfirmation(section)}
              >
                Delete
              </Button>
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="6" className="text-center">
          No sections available for your school.
        </td>
      </tr>
    )}
  </tbody>

        </Table>
      </div>
    </Card.Body>
    
    <div className="mt-3">
    <MyPagination
      currentPage={sectionsCurrentPage}
      totalSections={sections.length}
      sectionsPerPage={sectionsPerPage}
      onPageChange={(page) => setSectionsCurrentPage(page)}
    />
  </div>


  </Card>
  <Modal
  show={showAllStudentsModal}
  onHide={() => setShowAllStudentsModal(false)}
  size="xl"
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>All Students</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    {loadingAllStudents ? (
      <div className="d-flex justify-content-center my-4">
        <Spinner animation="border" />
      </div>
    ) : (
      <>
        {/* Search & Filter */}
        <Row className="mb-3 align-items-end">
          <Col md={5}>
            <Form.Group>
              <Form.Label className="fw-semibold">Search</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search by name, ID, or email"
                value={allStudentsSearchQuery}
                onChange={(e) => setAllStudentsSearchQuery(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group>
              <Form.Label className="fw-semibold">Date Added</Form.Label>
              <Form.Control
                type="date"
                value={allStudentsDateFilter}
                onChange={(e) => setAllStudentsDateFilter(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={3} className="text-end">
            <Dropdown>
              <Dropdown.Toggle variant="dark" className="w-100">
                <i className="bi bi-gear me-2" />
                More Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowGlobalDeadlineModal(true)}>
                  <i className="bi bi-calendar-check me-2" />
                  Set Global Deadline
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleResendInactive}
                  disabled={batchResendLoading}
                >
                  {batchResendLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Resending...
                    </>
                  ) : (
                    <>
                      <i className="bi bi-arrow-repeat me-2" />
                      Resend to All Inactive
                    </>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        {/* Primary Actions */}
        <Row className="mb-3">
          <Col className="d-flex gap-2 flex-wrap">
            <Button variant="success" onClick={openSingleStudentModal}>
              <i className="bi bi-person-plus me-2" />
              Add Student
            </Button>
            <Button variant="info" onClick={handleExportCSV}>
              <i className="bi bi-download me-2" />
              Export to CSV
            </Button>
          </Col>
        </Row>

        {/* Table */}
        {filteredAllStudents.length > 0 ? (
          <>
            <Table striped bordered hover responsive>
            <thead>
  <tr>
    <th onClick={() => handleSortAllStudents("id_no")} style={{ cursor: "pointer" }}>
      User ID <i className="bi bi-arrow-down-up ms-2" />
    </th>
    <th onClick={() => handleSortAllStudents("full_name")} style={{ cursor: "pointer" }}>
      Full Name <i className="bi bi-arrow-down-up ms-2" />
    </th>
    <th onClick={() => handleSortAllStudents("email")} style={{ cursor: "pointer" }}>
      Email <i className="bi bi-arrow-down-up ms-2" />
    </th>
    <th onClick={() => handleSortAllStudents("section_name")} style={{ cursor: "pointer" }}>
      Section <i className="bi bi-arrow-down-up ms-2" />
    </th>
    <th onClick={() => handleSortAllStudents("date_added")} style={{ cursor: "pointer" }}>
      Date Added <i className="bi bi-arrow-down-up ms-2" />
    </th>
    <th onClick={() => handleSortAllStudents("is_active")} style={{ cursor: "pointer" }}>
      Status <i className="bi bi-arrow-down-up ms-2" />
    </th>
    <th>Actions</th>
  </tr>
</thead>

              <tbody>
                {paginatedAllStudents.map((student) => (
                  <tr key={student.id}>
                    <td>{student.id_no}</td>
                    <td>{student.full_name}</td>
                    <td>{student.email}</td>
                    <td>{student.section_name || "N/A"}</td>
                    <td>{new Date(student.date_added).toLocaleDateString()}</td>
                    <td className="fw-bold text-center">
                      <span className={student.is_active ? "text-success" : "text-danger"}>
                        {student.is_active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td className="text-center">
                      <ButtonGroup size="sm">
                        <Button
                          variant="info"
                          onClick={() => handleResendEmail(student.id)}
                          disabled={resendLoading[student.id]}
                        >
                          {resendLoading[student.id] ? (
                            <>
                              <Spinner animation="border" size="sm" className="me-1" />
                              Sending...
                            </>
                          ) : (
                            "Resend"
                          )}
                        </Button>

                        <DropdownButton
                          as={ButtonGroup}
                          title="More"
                          variant="secondary"
                          size="sm"
                          align="end"
                        >
                          <Dropdown.Item onClick={() => openEditStudentModal(student)}>
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleToggleActive(student.id, student.is_active)}
                          >
                            {student.is_active ? "Deactivate" : "Activate"}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() => confirmDeleteStudent(student)}
                          >
                            Delete
                          </Dropdown.Item>
                        </DropdownButton>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <MyPagination
              currentPage={allStudentsCurrentPage}
              totalSections={filteredAllStudents.length}
              sectionsPerPage={allStudentsPerPage}
              onPageChange={setAllStudentsCurrentPage}
            />
          </>
        ) : (
          <p className="text-center mt-3">No students found matching your search criteria.</p>
        )}
      </>
    )}
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowAllStudentsModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

  {/* Add Single Student Modal */}
  <Modal
  show={showSingleStudentModal}
  onHide={() => setShowSingleStudentModal(false)}
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Add Single Student</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      {/* ID Number */}
      <Form.Group className="mb-2">
        <Form.Label>ID Number</Form.Label>
        <Form.Control
          name="id_no"
          value={singleStudent.id_no}
          onChange={handleSingleStudentChange}
          isInvalid={!!validationErrors.id_no}
        />
        <Form.Control.Feedback type="invalid">
          {validationErrors.id_no}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          name="full_name"
          value={singleStudent.full_name}
          onChange={handleSingleStudentChange}
          isInvalid={!!validationErrors.full_name}
        />
        <Form.Control.Feedback type="invalid">
          {validationErrors.full_name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Email</Form.Label>
        <Form.Control
          name="email"
          type="email"
          value={singleStudent.email}
          onChange={handleSingleStudentChange}
          isInvalid={!!validationErrors.email}
        />
        <Form.Control.Feedback type="invalid">
          {validationErrors.email}
        </Form.Control.Feedback>
      </Form.Group>

    <Row>
      {/* Gender Dropdown */}
      <Col>
        <Form.Group className="mb-2">
          <Form.Label>Gender</Form.Label>
          <Form.Select
            name="gender"
            value={singleStudent.gender}
            onChange={handleSingleStudentChange}
          >
            <option value="" hidden>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Form.Select>
        </Form.Group>
      </Col>

      {/* Grade Level Dropdown */}
      <Col>
        <Form.Group className="mb-2">
          <Form.Label>Grade Level</Form.Label>
          <Form.Select
            name="grade_level"
            value={singleStudent.grade_level}
            onChange={handleSingleStudentChange}
          >
            <option value="" hidden>Select Grade Level</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>

    <Row>
      {/* Section Dropdown (Fetched from Database) */}
      <Col>
        <Form.Group className="mb-2">
          <Form.Label>Section</Form.Label>
          <Form.Select
    name="section"
    value={singleStudent.section}
    onChange={handleSingleStudentChange}
  >
    <option value="" hidden>Select Section</option>
    {sections
      .filter((section) => section.name !== "WALK IN")
      .map((section) => (
        <option key={section.id} value={section.id}>
          {section.name}
        </option>
      ))}
  </Form.Select>

        </Form.Group>
      </Col>

      {/* Strand Dropdown (Fixed Options) */}
      <Col>
      <Form.Group className="mb-2">
    <Form.Label>Strand</Form.Label>
    <Form.Select
      name="strand"
      value={singleStudent.strand}
      onChange={handleSingleStudentChange}
    >
      <option value="" hidden>Select Strand</option>
      <option value="ABM">ABM</option>
      <option value="ARTS & DESIGN">ARTS & DESIGN</option>
      <option value="STEM">STEM</option>
      <option value="HUMSS">HUMSS</option>
      <option value="TVL - Information and Communications Technology">TVL - Information and Communications Technology</option>
      <option value="TVL - Home Economics">TVL - Home Economics</option>
      <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
      <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
    </Form.Select>
  </Form.Group>

      </Col>
    </Row>
  </Form>

    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowSingleStudentModal(false)}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleAddSingleStudent} disabled={addingSingleStudent}>
  {addingSingleStudent ? (
    <>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      {" "}Adding...
    </>
  ) : (
    "Add Student"
  )}
</Button>

    </Modal.Footer>
  </Modal>


    </Col>
  </Row>
  {/* Bulk Delete Confirmation Modal */}
  <Modal show={showBulkDeleteModal} onHide={() => setShowBulkDeleteModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Bulk Deletion</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Are you sure you want to delete the following sections? All users in these
        sections will have their section information removed. This action cannot
        be undone.
      </p>
      <ul>
        {sections
          .filter((section) => selectedSections.includes(section.id))
          .map((section) => (
            <li key={section.id}>
              <strong>{section.name}</strong> (ID: {section.id})
            </li>
          ))}
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowBulkDeleteModal(false)}>
        Cancel
      </Button>
      <Button variant="danger" onClick={handleConfirmedBulkDelete} disabled={deleting}>
        {deleting ? <Spinner animation="border" size="sm" /> : "Delete"}
      </Button>
    </Modal.Footer>
  </Modal>
{/* Upload Progress Modal */}
<Modal
  show={uploading}                // Show while "uploading" is true
  onHide={() => {}}               // Disable manual close
  backdrop="static"               // Don’t allow clicking outside
  keyboard={false}                // Don’t allow escape key
  centered
>
  <Modal.Body className="text-center">
    <Spinner animation="border" role="status" />
    <p className="mt-3">
      Upload in progress... {uploadProgress}%
    </p>
    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
  </Modal.Body>
</Modal>

  <Modal
    show={showBatchExtendedDeadlineModal}
    onHide={() => setShowBatchExtendedDeadlineModal(false)}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Set Extended Deadline for Selected Students</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group>
        <Form.Label>New Extended Deadline (Date & Time)</Form.Label>
        <Form.Control
          type="datetime-local"
          value={newBatchExtendedDeadline}
          onChange={(e) => setNewBatchExtendedDeadline(e.target.value)}
        />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowBatchExtendedDeadlineModal(false)}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleBatchExtendedDeadline}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>

  {/* Add Multiple Sections Modal */}
  <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add Multiple Sections</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group>
          <Form.Label>Section Names</Form.Label>
          {sectionNames.map((section, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                value={section}
                onChange={(e) => handleSectionNameChange(e, index)}
                placeholder={`Section ${index + 1}`}
                className="me-2"
              />
              <Button variant="danger" size="sm" onClick={() => removeSection(index)}>
                <i className="bi bi-trash"></i>
              </Button>
            </div>
          ))}
        </Form.Group>

        {/* Add More Section Button */}
        <Button
          variant="outline-primary"
          className="w-100 mt-3"
          onClick={addNewSectionField}
        >
          + Add Another Section
        </Button>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowAddModal(false)}>
        Cancel
      </Button>
      <Button
        variant="success"
        onClick={handleAddSection}
        disabled={loading || sectionNames.length === 0}
      >
        {loading ? <Spinner animation="border" size="sm" /> : "Add Sections"}
      </Button>
    </Modal.Footer>
  </Modal>
  <Modal
    show={showBatchMaxUsageModal}
    onHide={() => setShowBatchMaxUsageModal(false)}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Update Max Chatbot Usage for Selected Students</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group>
        <Form.Label>New Max Chatbot Usage</Form.Label>
        <Form.Control
          type="number"
          value={newBatchMaxUsage}
          onChange={(e) => setNewBatchMaxUsage(e.target.value)}
        />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowBatchMaxUsageModal(false)}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleBatchMaxChatbotUsage}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>
  <>
  <Modal
  show={showCSVModal}
  onHide={() => setShowCloseConfirmModal(true)}  // Trigger confirm modal
  size="xl"
  centered
  dialogClassName="modal-wider"
>
  <Modal.Header closeButton>
    <Modal.Title>Preview &amp; Edit Students Before Upload</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {/* Search Input for CSV Preview */}
    <Form.Group className="mb-3">
      <Form.Control
        type="text"
        placeholder="Search CSV by ID, Name, or Email"
        value={csvSearchQuery}
        onChange={(e) => {
          setCsvSearchQuery(e.target.value);
          setPreviewCurrentPage(1); // reset pagination on search
        }}
      />
    </Form.Group>

    {filteredCsvData.length > 0 ? (
      <>
        <Table striped bordered hover responsive className="csv-preview-table">
          <thead>
            <tr>
              <th className="csv-student-id">Student ID</th>
              <th className="csv-fullname">Full Name</th>
              <th className="csv-email">Email</th>
              <th className="csv-gender">Gender</th>
              <th className="csv-grade-level">Grade Level</th>
              <th className="csv-section">Section</th>
              <th className="csv-strand">Strand</th>
              <th className="csv-email-status">Email Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedPreviewStudents.map((student, index) => {
              const globalIndex = indexOfFirstPreviewStudent + index;
              return (
                <tr key={globalIndex}>
                  <td>
                    <Form.Control
                      type="text"
                      value={student.id_no}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "id_no", e.target.value)
                      }
                    />
                  </td>
                  <td className="csv-fullname">
                    <Form.Control
                      type="text"
                      value={student.full_name}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "full_name", e.target.value)
                      }
                    />
                  </td>
                  <td className="csv-email">
                    <Form.Control
                      type="email"
                      value={student.email}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "email", e.target.value)
                      }
                    />
                  </td>
                  <td className="csv-gender">
                    <Form.Select
                      value={student.gender}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "gender", e.target.value)
                      }
                    >
                      <option value="">Select Gender</option>
                      {genderOptions.map((g) => (
                        <option key={g} value={g}>
                          {g}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="csv-grade-level">
                    <Form.Select
                      value={student.grade_level}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "grade_level", e.target.value)
                      }
                    >
                      <option value="">Select Grade Level</option>
                      {gradeLevelOptions.map((level) => (
                        <option key={level} value={level}>
                          {level}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="csv-section">
                    <Form.Select
                      value={student.section}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "section", e.target.value)
                      }
                    >
                      <option value="">Select Section</option>
                      {sections.map((sec) => (
                        <option key={sec.id} value={sec.name}>
                          {sec.name}
                        </option>
                      ))}
                      {student.section &&
                        !sections.find((sec) => sec.name === student.section) && (
                          <option value={student.section}>
                            {student.section} (New)
                          </option>
                        )}
                    </Form.Select>
                  </td>
                  <td className="csv-strand">
                    <Form.Select
                      value={student.strand}
                      onChange={(e) =>
                        handleCsvRowChange(globalIndex, "strand", e.target.value)
                      }
                    >
                      <option value="">Select Strand</option>
                      {strandOptions.map((strand) => (
                        <option key={strand} value={strand}>
                          {strand}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="csv-email-status">
                    {student.emailStatus === "loading" ? (
                      <Spinner animation="border" size="sm" />
                    ) : student.emailStatus === "sent" ? (
                      <span className="text-success">&#10003;</span>
                    ) : (
                      <span className="text-warning">Pending</span>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => confirmRemoveCsvRow(globalIndex)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <MyPagination
          currentPage={previewCurrentPage}
          totalSections={filteredCsvData.length}
          sectionsPerPage={previewStudentsPerPage}
          onPageChange={(page) => setPreviewCurrentPage(page)}
        />
      </>
    ) : (
      <Alert variant="warning" className="text-center">
        No students loaded yet.
      </Alert>
    )}

    <h5 className="mt-3">Manually Add a Student</h5>
    <Form>
        <Row className="mb-2">
          <Col>
            <Form.Control
              type="text"
              placeholder="ID Number"
              value={newStudent.id_no}
              onChange={(e) =>
                setNewStudent({ ...newStudent, id_no: e.target.value })
              }
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Full Name"
              value={newStudent.full_name}
              onChange={(e) =>
                setNewStudent({ ...newStudent, full_name: e.target.value })
              }
            />
          </Col>
          <Col>
            <Form.Control
              type="email"
              placeholder="Email"
              value={newStudent.email}
              onChange={(e) =>
                setNewStudent({ ...newStudent, email: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Select
              value={newStudent.gender}
              onChange={(e) =>
                setNewStudent({ ...newStudent, gender: e.target.value })
              }
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Select
              value={newStudent.grade_level}
              onChange={(e) =>
                setNewStudent({ ...newStudent, grade_level: e.target.value })
              }
            >
              <option value="">Select Grade Level</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Select
              value={newStudent.section}
              onChange={(e) =>
                setNewStudent({ ...newStudent, section: e.target.value })
              }
            >
              <option value="">Select Section</option>
              {sections.map((section) => (
                <option key={section.id} value={section.id}>
                  {section.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Form.Select
              value={newStudent.strand}
              onChange={(e) =>
                setNewStudent({ ...newStudent, strand: e.target.value })
              }
            >
              <option value="">Select Strand</option>
              <option value="ABM">ABM</option>
              <option value="ARTS & DESIGN">ARTS & DESIGN</option>
              <option value="STEM">STEM</option>
              <option value="HUMSS">HUMSS</option>
              <option value="TVL - Information and Communications Technology">
                TVL - Information and Communications Technology
              </option>
              <option value="TVL - Home Economics">TVL - Home Economics</option>
              <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
              <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
            </Form.Select>
          </Col>
        </Row>
        <Button variant="success" onClick={handleAddManualStudent} className="w-100">
          Add Student
        </Button>
      </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowCloseConfirmModal(true)}>
      Close
    </Button>

    <Button variant="primary" onClick={handleUploadCSV} disabled={uploading}>
      {uploading ? <Spinner animation="border" size="sm" /> : "Upload Students"}
    </Button>
  </Modal.Footer>
</Modal>

<Modal
  show={showCloseConfirmModal}
  onHide={() => setShowCloseConfirmModal(false)}
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Close</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to close the CSV preview? Any unsaved changes will be lost.
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowCloseConfirmModal(false)}>
      Cancel
    </Button>
    <Button variant="danger" onClick={confirmCloseCSV}>
      Yes, Close
    </Button>
  </Modal.Footer>
</Modal>

  {/* Confirmation Modal for Removing a Row */}
  <Modal
    show={showRemoveConfirmModal}
    onHide={() => setShowRemoveConfirmModal(false)}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Confirm Removal</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to remove this row?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowRemoveConfirmModal(false)}>
        Cancel
      </Button>
      <Button variant="danger" onClick={handleConfirmRemove}>
        Remove
      </Button>
    </Modal.Footer>
  </Modal>
</>




        {/* Edit Section Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Section Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedSection?.name || ""}
                  onChange={(e) =>
                    setSelectedSection((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the section{" "}
            <strong>{sectionToDelete?.name}</strong>? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteSection} disabled={deleting}>
              {deleting ? <Spinner animation="border" size="sm" /> : "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
    show={showStudentsModal}
    onHide={handleCloseStudentsModal}
    size="xl"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Students in Section {selectedSectionName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {/* Search Input */}
          <Form.Control
            type="text"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={handleSearchChange}
            className="mb-3"
          />

          {/* Batch Action Buttons */}
          {selectedStudentsForBatchActions.length > 0 && (
    <div className="mb-3 d-flex gap-2">
      <Button
        variant="secondary"
        onClick={() => setShowBatchExtendedDeadlineModal(true)}
      >
        Batch Extend Deadline
      </Button>
      <Button
        variant="primary"
        onClick={() => setShowBatchMaxUsageModal(true)}
      >
        Batch Update Max Chatbot Usage
      </Button>
    </div>
  )}


          {/* Students Table */}
          {filteredStudents.length > 0 ? (
            <>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    {/* Header checkbox for batch actions */}
                    <th className="text-center">
                      <Form.Check
                        type="checkbox"
                        checked={paginatedStudents.every((s) =>
                          selectedStudentsForBatchActions.includes(s.id)
                        )}
                        onChange={() => {
                          const ids = paginatedStudents.map((s) => s.id);
                          const allSelected = ids.every((id) =>
                            selectedStudentsForBatchActions.includes(id)
                          );
                          if (allSelected) {
                            setSelectedStudentsForBatchActions((prev) =>
                              prev.filter((id) => !ids.includes(id))
                            );
                          } else {
                            setSelectedStudentsForBatchActions((prev) =>
                              Array.from(new Set([...prev, ...ids]))
                            );
                          }
                        }}
                      />
                    </th>
                    <th onClick={() => handleSortSectionStudents("id_no")} style={{ cursor: "pointer" }}>
                      Student ID <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th onClick={() => handleSortSectionStudents("full_name")} style={{ cursor: "pointer" }}>
                      Full Name <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th onClick={() => handleSortSectionStudents("grade_level")} style={{ cursor: "pointer" }}>
                      Grade Level <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th onClick={() => handleSortSectionStudents("has_taken_test")} style={{ cursor: "pointer" }}>
                      Test Status <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th onClick={() => handleSortSectionStudents("chatbot_starts")} style={{ cursor: "pointer" }}>
                      Chatbot Usage <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th className="text-center" onClick={() => handleSortSectionStudents("max_chatbot_starts")} style={{ cursor: "pointer" }}>
                      Max Chatbot Usage <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th onClick={() => handleSortSectionStudents("is_active")} style={{ cursor: "pointer" }}>
                      Account Status <i className="bi bi-arrow-down-up"></i>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedStudents.map((student) => (
                    <tr key={student.id}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={selectedStudentsForBatchActions.includes(student.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedStudentsForBatchActions((prev) => [...prev, student.id]);
                            } else {
                              setSelectedStudentsForBatchActions((prev) =>
                                prev.filter((id) => id !== student.id)
                              );
                            }
                          }}
                        />
                      </td>
                      <td>{student.id_no}</td>
                      <td>{student.full_name}</td>
                      <td>{student.grade_level}</td>
                      <td>
                        {student.has_taken_test ? (
                          <span className="text-success">Taken - {student.test_date}</span>
                        ) : (
                          <span className="text-danger">Not Taken</span>
                        )}
                      </td>
                      <td>
                        <span className={student.chatbot_starts >= student.max_chatbot_starts ? "text-danger fw-bold" : "text-success fw-bold"}>
                          {student.chatbot_starts}/{student.max_chatbot_starts}{" "}
                          {student.chatbot_starts >= student.max_chatbot_starts ? "(Maxed Out)" : `(Remaining: ${student.max_chatbot_starts - student.chatbot_starts})`}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="d-flex flex-column align-items-center">
                          <Form.Control
                            type="number"
                            min="1"
                            value={maxStarts[student.id] || student.max_chatbot_starts}
                            onChange={(e) => setMaxStarts((prev) => ({ ...prev, [student.id]: e.target.value }))}
                            style={{ width: "80px", textAlign: "center" }}
                          />
                          <Button
                            variant="primary"
                            size="sm"
                            className="mt-1"
                            onClick={() => handleUpdateMaxStarts(student.id)}
                            disabled={!maxStarts[student.id] || parseInt(maxStarts[student.id]) === student.max_chatbot_starts}
                          >
                            Update
                          </Button>
                        </div>
                      </td>
                      <td>
                        {student.is_active ? (
                          <span className="text-success fw-bold">Active</span>
                        ) : (
                          <span className="text-danger fw-bold">Inactive</span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex justify-content-center gap-2">
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() => handleResendEmail(student.id)}
                            disabled={resendLoading[student.id]}
                          >
                            {resendLoading[student.id] ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                                Sending...
                              </>
                            ) : (
                              "Resend Email"
                            )}
                          </Button>
                          <Button
                            variant={student.is_active ? "danger" : "success"}
                            size="sm"
                            onClick={() => handleToggleActive(student.id, student.is_active)}
                          >
                            {student.is_active ? "Deactivate" : "Activate"}
                          </Button>
                         

                          {/* Render the "Set Extended Deadline" button only if:
                                - The student has not taken the test
                                - A global deadline is defined
                                - The current date is past the global deadline */}
                          { !student.has_taken_test &&
                            globalDeadline &&
                            new Date() > globalDeadline && (
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => openExtendedDeadlineModal(student)}
                              >
                                Set Extended Deadline
                              </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Pagination Controls */}
              <MyPagination
                currentPage={currentPage}
                totalSections={filteredStudents.length}
                sectionsPerPage={studentsPerPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          ) : (
            <p className="text-center">No students found matching your search criteria.</p>
          )}
        </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseStudentsModal}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>


  <Modal
  show={showDeleteStudentModal}
  onHide={() => setShowDeleteStudentModal(false)}
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Delete</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to delete <strong>{studentToDelete?.full_name}</strong>?
    This action cannot be undone.
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowDeleteStudentModal(false)}>
      Cancel
    </Button>
    <Button variant="danger" onClick={handleDeleteStudent}>
      Delete
    </Button>


  </Modal.Footer>
</Modal>
<Modal
  show={showEditStudentModal}
  onHide={() => setShowEditStudentModal(false)}
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Edit Student Details</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    {studentToEdit && (
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>ID Number</Form.Label>
              <Form.Control
                name="id_no"
                value={studentToEdit.id_no}
                onChange={handleEditStudentChange}
                isInvalid={!!editValidationErrors.id_no}
              />
              <Form.Control.Feedback type="invalid">
                {editValidationErrors.id_no}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                value={studentToEdit.email}
                onChange={handleEditStudentChange}
                isInvalid={!!editValidationErrors.email}
              />
              <Form.Control.Feedback type="invalid">
                {editValidationErrors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            name="full_name"
            value={studentToEdit.full_name}
            onChange={handleEditStudentChange}
            isInvalid={!!editValidationErrors.full_name}
          />
          <Form.Control.Feedback type="invalid">
            {editValidationErrors.full_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Section</Form.Label>
              <Form.Select
                name="section_name"
                value={studentToEdit.section_name || ""}
                onChange={handleEditStudentChange}
              >
                <option value="" hidden>Select Section</option>
                {sections.map((section) => (
                  <option key={section.id} value={section.name}>
                    {section.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Grade Level</Form.Label>
              <Form.Select
                name="grade_level"
                value={studentToEdit.grade_level ? String(studentToEdit.grade_level) : ""}
                onChange={handleEditStudentChange}
                isInvalid={!!editValidationErrors.grade_level}
              >
                <option value="" hidden>Select Grade Level</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {editValidationErrors.grade_level}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>Strand</Form.Label>
          <Form.Select
            name="strand"
            value={studentToEdit.strand || ""}
            onChange={handleEditStudentChange}
            isInvalid={!!editValidationErrors.strand}
          >
            <option value="" hidden>Select Strand</option>
            <option value="ABM">ABM</option>
            <option value="ARTS & DESIGN">ARTS & DESIGN</option>
            <option value="STEM">STEM</option>
            <option value="HUMSS">HUMSS</option>
            <option value="TVL - Information and Communications Technology">
              TVL - Information and Communications Technology
            </option>
            <option value="TVL - Home Economics">TVL - Home Economics</option>
            <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
            <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {editValidationErrors.strand}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    )}
  </Modal.Body>

  <Modal.Footer className="d-flex justify-content-between flex-wrap gap-2">
    <Button
      variant="primary"
      onClick={handleSaveEditedStudent}
      disabled={editLoading}
      className="w-100 w-md-auto"
    >
      {editLoading ? (
        <>
          <Spinner animation="border" size="sm" className="me-2" /> Saving...
        </>
      ) : (
        <>
          <i className="bi bi-save me-2" />
          Save Changes
        </>
      )}
    </Button>

    <Button
      variant="warning"
      onClick={handleResendEmailFromEdit}
      disabled={resendLoading[studentToEdit?.id]}
      className="w-100 w-md-auto"
    >
      {resendLoading[studentToEdit?.id] ? (
        <>
          <Spinner animation="border" size="sm" className="me-2" /> Sending...
        </>
      ) : (
        <>
          <i className="bi bi-envelope-check me-2" />
          Save & Resend Email
        </>
      )}
    </Button>
  </Modal.Footer>
</Modal>





 

</Container>
    );
};

export default Management;
