import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  useEffect(() => {
    const storedLang = localStorage.getItem('language');
    if (storedLang && storedLang !== language) {
      setLanguage(storedLang);
    }
  }, []);  // ✅ Syncs state with localStorage on mount

  const switchLanguage = (lang) => {
    console.log("Switching language to:", lang);
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
