import React, { useEffect, useRef, useState, useContext, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import Cookies from 'universal-cookie';
import { ChatbotContext } from '../../context/ChatbotContext';
import { MdClose, MdLanguage } from 'react-icons/md';  // Alternative Language Icon
import { FaUserGraduate } from 'react-icons/fa';
import FeedbackModal from '../pages/FeedbackModal'; 
import { useLanguage } from '../../context/LanguageContext';
import Message from './Message';
import Card from './Card';
import chathead from '../../assets/Anna_Chathead.svg';
import chatloading from '../../assets/chatbot-loading.gif';
import chatbotAvatar from '../../assets/Anna_Chat_Avatar.svg';
import QuickReplies from './QuickReplies';
import Modal from '../Modal';
import RecommendedCoursesMessage from './RecommendedCoursesMessage';
import RecommendedCoursesQuickReply from './RecommendedCoursesQuickReply';
import { titleCase } from '../../utils/utilityFunctions';
import api from '../../utils/api'
import '../../styles/chatbot.css';
import { Row, Col, Spinner, ListGroup, Button, Dropdown, Form, InputGroup} from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa'; // Import Language Icon
import { IoLanguageOutline } from 'react-icons/io5'; // Another option
import { FaGlobeAmericas } from 'react-icons/fa'; // Another optiodn
import { MdSend } from 'react-icons/md'; // Add this line
import apiNode from '../../utils/apiNode';
import { motion } from "framer-motion";
import { FaSearch } from 'react-icons/fa';

const cookies = new Cookies();

const Chatbot = () => {
const [refresh, setRefresh] = useState(false);
const { language, switchLanguage } = useLanguage();
const [messages, setMessages] = useState([
    {
        speaks: 'bot',
        keyword: 'terms-conditions',
        msg: {
            text: {
                text: language === 'fil'
                    ? 'Kumusta. Bago tayo magsimula, sa session na ito ay kukunin ko ang iyong pangunahing impormasyon. Kailangan mo munang basahin at sumang-ayon sa mga tuntunin na ipinakita sa'
                    : 'Hello. Before we begin, in this session I will take your basic information. You must first read and agree on the terms presented in the',
            },
        },
    },
]);
const [textMessage, setTextMessage] = useState('');
const {
    isAgreeTermsConditions,
    setIsAgreeTermsConditions,
    showBot,
    setShowbot,
    inputRef,
    disabledInput,
    setDisabledInput,
    setIsVisibleInput,
    isVisibleInput,
    botChatLoading,
    setBotChatLoading,
    basis,
    setBasis,
    setIsRecommendationProvided,
    isRecommendationProvided,
} = useContext(ChatbotContext);

const messagesRef = useRef(null);
const [courseOptionsTimer, setCourseOptionsTimer] = useState('');
const [user, setUser] = useState({ name: '', age: '', sex: '', strand:'' });
const [riasec, setRiasec] = useState({ realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 });
const [riasecCode, setRiasecCode] = useState([]);
const [fallbackCount, setFallbackCount] = useState({});
const [endConversation, setEndConversation] = useState(false);
const [isBasicInfoProvided, setIsBasicInfoProvided] = useState(false);
const [testStarted, setTestStarted] = useState(false);
const [knownCourses, setKnownCourses] = useState([]);
const [riasecBasedRecommendedCourses, setRiasecBasedRecommendedCourses] = useState([]);
const [strandBasedRecommendedCourses, setStrandBasedRecommendedCourses] = useState([]);
const [isLoggedIn, setIsLoggedIn] = useState(false);
const [isStrandRecommendationTriggered, setIsStrandRecommendationTriggered] = useState(false);
const [showAllCourses, setShowAllCourses] = useState(false);
const [expandedCourses, setExpandedCourses] = useState({});
const DEFAULT_VISIBLE_COURSES = 10; // Default number of visible courses
    const MAX_VISIBLE_COURSES = 30; // Maximum courses that can be shown
const [visibleCourses, setVisibleCourses] = useState(DEFAULT_VISIBLE_COURSES);
const [courseSearchTerm, setCourseSearchTerm] = useState('');

const [showFeedbackModal, setShowFeedbackModal] = useState(false);
const [rating, setRating] = useState(0);
const [feedbackComment, setFeedbackComment] = useState('');  
const [riasecCourses, setRiasecCourses] = useState({});
const [coursePreview, setCoursePreview] = useState([]);
const [isLoadingCourses, setIsLoadingCourses] = useState(false);
if (!cookies.get('userId') || cookies.get('userId').trim() === '') {
    const newUserId = uuid();
    cookies.set('userId', newUserId, { path: '/' });
    console.log('Assigned new userId:', newUserId);
}
const fetchUserInfo = async () => {
    try {
      const accessToken = localStorage.getItem('authToken');
      const refreshToken = localStorage.getItem('refreshToken');
  
      if (!accessToken) {
        setIsLoggedIn(false);
        setUser(null);
        return;
      }
  
      const checkLoginStatus = async (token) => {
        return api.get('/api/check_login_status/', {
          headers: { Authorization: `Bearer ${token}` },
        });
      };
  
      let response = await checkLoginStatus(accessToken);
  
      if (response.status === 401 && refreshToken) {
        const refreshResponse = await api.post('/api/token/refresh/', {
          refresh: refreshToken,
        });
        if (refreshResponse.status === 200) {
          localStorage.setItem('authToken', refreshResponse.data.access);
          response = await checkLoginStatus(refreshResponse.data.access);
        } else {
          setIsLoggedIn(false);
          setUser(null);
          return;
        }
      }
  
      const data = response.data;
      if (response.status === 200 && data.is_logged_in && data.user_data) {
        setUser({
          name: data.user_data.name,
          age: data.user_data.age,
          sex: data.user_data.sex,
          strand: data.user_data.strand,
          chatbot_starts: data.user_data.chatbot_starts,
          max_chatbot_starts: data.user_data.max_chatbot_starts,
          extended_deadline: data.user_data.extended_deadline, // Optional individual override
        });
        setIsLoggedIn(true);
        setIsBasicInfoProvided(true);
  
        if (isAgreeTermsConditions && !testStarted) {
          if (data.user_data.chatbot_starts < data.user_data.max_chatbot_starts) {
            console.log('Starting RIASEC test for user:', data.user_data.name);
  
            // Multi-language Welcome Message
            const welcomeMessage = {
              speaks: 'bot',
              msg: {
                text: {
                  text:
                    language === 'fil'
                      ? `Kumusta ${data.user_data.name}! Maligayang pagbabalik 😊. Simulan na natin ang iyong RIASEC test.`
                      : `Hello ${data.user_data.name}! Welcome, I'm Anna 😊. Let's get started with your RIASEC test.`,
                },
              },
            };
  
            setMessages((prev) => [...prev, welcomeMessage]);
            await df_event_query('RIASEC_START');
            setTestStarted(true);
          } else {
            console.log('Max chatbot starts reached. Test cannot be started.');
            setShowbot(false);
          }
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setIsBasicInfoProvided(false);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setIsLoggedIn(false);
      setUser(null);
    }
  };
  
  useEffect(() => {
    fetchUserInfo();
  }, []);
  
  
useEffect(() => {
    // Listen for chatbot refresh event after login
    const handleChatbotRefresh = () => {
        // Re-fetch the user info and refresh the chatbot state
        fetchUserInfo();  // This will trigger the chatbot to refresh with the user's data
    };
    
    window.addEventListener('chatbotRefresh', handleChatbotRefresh);
    
    // Cleanup the event listener
    return () => {
        window.removeEventListener('chatbotRefresh', handleChatbotRefresh);
    };
    }, []);
    const fetchRiasecCourses = async (riasecCode) => {
        if (!riasecCode || riasecCode.length === 0) return;
    
        setIsLoadingCourses(true);
        let coursesByCategory = {};
        let preview = [];
    
        try {
            for (let i = 0; i < riasecCode.length; i++) {
                const [category] = riasecCode[i];
                const response = await apiNode.get(`/api/courses?riasec=${category}`);
                const courses = response.data || [];
    
                coursesByCategory[category] = courses;
    
                if (i === 0) {
                    preview.push(...courses.slice(0, 4)); // First category gets 4 courses
                } else {
                    preview.push(...courses.slice(0, 3)); // Others get 3 each
                }
            }
    
            setRiasecCourses(coursesByCategory);
            setCoursePreview(preview);
        } catch (error) {
            console.error('Error fetching courses:', error);
        } finally {
            setIsLoadingCourses(false);
        }
    };

    useEffect(() => {
    // Re-fetch the necessary chatbot state or trigger RIASEC_START
    // You can fetch the user info here or refresh the chatbot state
    }, [refresh]);
useEffect(() => {
    const handleLoginSuccess = (e) => {
        const userData = e.detail;
    
        setUser({
        name: userData.name,
        age: userData.age,
        sex: userData.sex,
        strand: userData.strand,
        });
    
        // Remove the df_event_query('RIASEC_START') from here
    };
    
    window.addEventListener('loginSuccess', handleLoginSuccess);
    
    return () => {
        window.removeEventListener('loginSuccess', handleLoginSuccess);
    };
    }, []);
    const df_text_query = async (text, parameters = {}) => {
        try {
            // Ensure userId exists in cookies
            let userId = cookies.get('userId');
            if (!userId || userId.trim() === '') {
                userId = uuid();
                cookies.set('userId', userId, { path: '/' });
                console.log('Generated new session ID:', userId);
            }
    
            const currentLanguage = language || 'en';
            const userSays = {
                speaks: 'user',
                msg: { text: { text: text } },
            };
    
            // Remove any preceding quick reply message
            if (messages[messages.length - 1]?.msg?.payload?.fields?.quick_replies) {
                removeQuickRepliesAfterType(messages, setMessages);
            }
    
            setMessages((prev) => [...prev, userSays]);
            setBotChatLoading(true);
    
            const body = { text, userId, parameters, lang: currentLanguage };
            console.log('📡 Sending text query to Dialogflow:', body);
    
            const response = await fetch('https://node-backend-604521917673.asia-northeast1.run.app/api/df_text_query/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            });
    
            const data = await response.json();
            setBotChatLoading(false);
            console.log('🔍 Dialogflow response:', data);
    
            if (response.status !== 200 || !data) {
                throw new Error('❌ Failed to process Dialogflow text query');
            }
    
            // ✅ Fix: Extract Name Correctly (Handles Nested Structure)
            const extractedName = data.parameters?.fields?.name?.listValue?.values?.[0]?.structValue?.fields?.name?.stringValue;
            if (extractedName) {
                console.log("✅ Extracted Name from Dialogflow:", extractedName);
                setUser((prev) => ({ ...prev, name: extractedName }));
            }
    
            // Handle responses based on intents
            if (data.intent?.displayName === 'Default Welcome Intent') {
                clearState();
                return;
            }
    
            if (!data.intent || endConversation) {
                console.warn('⚠️ No matching intent or end of conversation detected.');
                df_event_query('FALLBACK_EXCEED_TRIGGER_LIMIT');
                clearState();
                setEndConversation(true);
                return;
            }
    
            // Handle fallback intents
            if (data.intent?.isFallback) {
                const intentName = data.intent.displayName;
                fallbackCount[intentName] = (fallbackCount[intentName] || 0) + 1;
    
                if (fallbackCount[intentName] >= 5) {
                    console.warn('🚨 Fallback limit exceeded for intent:', intentName);
                    df_event_query('FALLBACK_EXCEED_TRIGGER_LIMIT');
                    clearState();
                    setEndConversation(true);
                    return;
                }
            }
    
            // Update user data based on parameters
            const fields = data.parameters?.fields;
            if (fields) {
                if (fields.age?.numberValue) setUser((prev) => ({ ...prev, age: fields.age.numberValue }));
                if (fields.sex?.stringValue) setUser((prev) => ({ ...prev, sex: fields.sex.stringValue }));
                if (fields.strand?.stringValue) setUser((prev) => ({ ...prev, strand: fields.strand.stringValue }));
            }
    
            // Handle fulfillment messages
            data.fulfillmentMessages.forEach((msg) => {
                const botSays = { speaks: 'bot', msg };
                setMessages((prev) => [...prev, botSays]);
    
                const payloadFields = msg.payload?.fields;
    
                // ✅ RIASEC Handling
                if (payloadFields?.riasec?.stringValue) {
                    const riasecValue = payloadFields.riasec.stringValue;
                    console.log('🔍 RIASEC value received:', riasecValue);
    
                    setRiasec((prev) => {
                        const updatedRiasec = { ...prev };
                        switch (riasecValue) {
                            case 'realistic':
                                updatedRiasec.realistic += 1;
                                break;
                            case 'investigative':
                                updatedRiasec.investigative += 1;
                                break;
                            case 'artistic':
                                updatedRiasec.artistic += 1;
                                break;
                            case 'social':
                                updatedRiasec.social += 1;
                                break;
                            case 'enterprising':
                                updatedRiasec.enterprising += 1;
                                break;
                            case 'conventional':
                                updatedRiasec.conventional += 1;
                                break;
                            default:
                                console.warn('⚠️ Unknown RIASEC value:', riasecValue);
                        }
                        return updatedRiasec;
                    });
                }
    
                // ✅ Strand Recommendation Trigger
                if (payloadFields?.iswant_strand_recommendation?.boolValue && !isStrandRecommendationTriggered) {
                    console.log('🔹 Triggering strand recommendation...');
                    df_event_query('STRAND_RECOMMENDATION', { strand: user.strand });
                    setIsStrandRecommendationTriggered(true);
                    setIsRecommendationProvided((prev) => ({ ...prev, strand: 'done' }));
                }
    
                // ✅ RIASEC Last Question Handling
                if (payloadFields?.riasec_last_question?.boolValue && !isRecommendationProvided.riasec) {
                    handleRiasecRecommendation(riasec);
                    setIsRecommendationProvided((prev) => ({ ...prev, riasec: 'done' }));
                }
    
                // ✅ End of Conversation Handling
                if (payloadFields?.end_conversation?.boolValue) {
                    console.log('🔹 Ending conversation...');
                    savedConversation(user, riasecCode, riasecCourses, strandBasedRecommendedCourses, riasec);
                    clearState();
                    setDisabledInput(true);
                    setIsVisibleInput(false);
                    setIsStrandRecommendationTriggered(false);
                }
            });
        } catch (err) {
            console.error('❌ Error in Dialogflow text query:', err.message);
            setBotChatLoading(false);
    
            const botSays = {
                speaks: 'bot',
                msg: {
                    text: { text: '🚨 Sorry, I am having trouble. I need to terminate. I will be back later.' },
                },
            };
            setMessages((prev) => [...prev, botSays]);
        }
    };
    

    const df_event_query = async (event, parameters = {}) => {
        try {
            setBotChatLoading(true);
    
            let sessionId = cookies.get('userId');
            if (!sessionId || sessionId.trim() === '') {
                sessionId = uuid();
                cookies.set('userId', sessionId, { path: '/' });
                console.log('Generated new session ID:', sessionId);
            }
            
            const currentLanguage = language || 'en';
            const body = { event, userId: sessionId, parameters, lang: currentLanguage };
    
            console.log('Sending event to Dialogflow:', body);
    
            const response = await fetch(
                'https://node-backend-604521917673.asia-northeast1.run.app/api/df_event_query/',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body),
                }
            );
    
            const data = await response.json();
            setBotChatLoading(false);
    
            if (response.status !== 200) {
                throw new Error(`Failed to fetch Dialogflow event. Status: ${response.status}`);
            }
    
            console.log('Dialogflow response:', data);
            console.log('Sending event query with language:', body.lang);
    
            // Extract name from parameters (even if set via event)
            // ✅ Fix: Extract Name Correctly (Handles Nested Structure)
            const extractedName = data.parameters?.fields?.name?.listValue?.values?.[0]?.structValue?.fields?.name?.stringValue;
            if (extractedName) {
                console.log("✅ Extracted Name from Event Query:", extractedName);
                setUser((prev) => ({ ...prev, name: extractedName }));
            }
        
            // Append fulfillment messages to `messages` state
            if (data.fulfillmentMessages) {
                data.fulfillmentMessages.forEach((msg) => {
                    const botSays = {
                        speaks: 'bot',
                        msg,
                    };
                    setMessages((prev) => [...prev, botSays]); // Append to messages state
                });
            } else {
                console.warn('No fulfillment messages found in response.');
            }
    
            // Handle Default Welcome Intent
            if (data.intent?.displayName === 'Default Welcome Intent') {
                console.log('Triggered Default Welcome Intent.');
                return; // Do not clear state
            }
    
            // Handle fallback intents or unknown intents
            if (!data.intent) {
                console.warn('No intent matched. Triggering fallback...');
                await df_event_query('FALLBACK_EXCEED_TRIGGER_LIMIT');
                clearState();
                setEndConversation(true);
                setDisabledInput(false);
                setIsVisibleInput(true);
                return;
            }
    
            // Process fulfillment messages with additional payload fields
            data.fulfillmentMessages.forEach((msg) => {
                const fields = msg.payload?.fields;
    
                if (!fields) {
                    console.warn('No fields found in payload. Skipping message:', msg);
                    return;
                }
    
                // Handle strand recommendation trigger
                if (fields.iswant_strand_recommendation?.boolValue && !isStrandRecommendationTriggered) {
                    console.log('Triggering strand recommendation...');
                    df_event_query('STRAND_RECOMMENDATION', { strand: user.strand });
                    setIsStrandRecommendationTriggered(true);
                    setIsRecommendationProvided((prev) => ({ ...prev, strand: 'done' }));
                }
    
                // Handle RIASEC logic
                if (fields.riasec?.stringValue) {
                    const riasecValue = fields.riasec.stringValue;
                    console.log('Received RIASEC value:', riasecValue);
    
                    setRiasec((prev) => {
                        const updatedRiasec = { ...prev };
                        switch (riasecValue) {
                            case 'realistic':
                                updatedRiasec.realistic += 1;
                                break;
                            case 'investigative':
                                updatedRiasec.investigative += 1;
                                break;
                            case 'artistic':
                                updatedRiasec.artistic += 1;
                                break;
                            case 'social':
                                updatedRiasec.social += 1;
                                break;
                            case 'enterprising':
                                updatedRiasec.enterprising += 1;
                                if (fields.riasec_last_question?.boolValue) {
                                    handleRiasecRecommendation(updatedRiasec);
                                }
                                break;
                            case 'conventional':
                                updatedRiasec.conventional += 1;
                                break;
                            default:
                                console.warn('Unknown RIASEC value:', riasecValue);
                                break;
                        }
                        return updatedRiasec;
                    });
                }
    
                // Handle recommendations
                if (fields.riasec_recommended_courses?.listValue?.values) {
                    const courses = fields.riasec_recommended_courses.listValue.values.map(
                        (course) => course.stringValue
                    );
                    console.log('RIASEC Recommended Courses:', courses);
                    setRiasecBasedRecommendedCourses(courses);
                }
    
                if (fields.strand_recommended_courses?.listValue?.values) {
                    const courses = fields.strand_recommended_courses.listValue.values.map(
                        (course) => course.stringValue
                    );
                    console.log('Strand Recommended Courses:', courses);
                    setStrandBasedRecommendedCourses(courses);
                }
    
                // Handle end of conversation
                if (fields.end_conversation?.boolValue) {
                    console.log('Ending conversation...');
                    savedConversation(user, riasecCode, riasecCourses, strandBasedRecommendedCourses, riasec);
                    clearState();
                    setDisabledInput(true);
                    setIsVisibleInput(false);
                }
            });
        } catch (err) {
            console.error('Error sending event to Dialogflow:', err.message);
    
            setBotChatLoading(false);
    
            // Add a generic error message to the `messages` state
            const botSays = {
                speaks: 'bot',
                msg: {
                    text: {
                        text: 'Sorry, something went wrong 🤕. Please try again later.',
                    },
                },
            };
            setMessages((prev) => [...prev, botSays]);
        }
    };
    

const triggerCourseOptionYes = () => {
    // this will keep the context exceeds the time limit of 20mins, because users might take time watching the videos
    // after the  course options was rendered, trigger the course option timer after 15 minutes to reset the timer of the intent's context
    // will be cleared after clicking "continue" quick reply
    // only trigger the timer when courseOptionsTimer is empty or no courseOptiontimer tiggered to avoid duplication of timer when component is rendered again
    // timer will still tigger even card component is unmounted
    if (!courseOptionsTimer) {
        const timerId = setInterval(async () => {
            try {
            const body = { event: 'COURSE_OPTIONS_YES', userId: cookies.get('userId') };
            await fetch('https://node-backend-604521917673.asia-northeast1.run.app/api/df_event_query', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            });
            console.log('course options timer triggered!');
            } catch (err) {
            console.error(err.message);
            }
        }, 900000);
        setCourseOptionsTimer(timerId);
    }
};

const clearCourseOptionsYes = () => {
    // clear the timer
    clearInterval(courseOptionsTimer);
    setCourseOptionsTimer('');
    console.log('course options timer cleared');
};

const clearState = () => {
    setUser({ name: '', age: '', sex: '', strand: '' });
    setRiasec({ realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 });
    setRiasecCode([]);
    setKnownCourses([]);
    setRiasecBasedRecommendedCourses([]);
    setStrandBasedRecommendedCourses([]);
    setFallbackCount({});
    setEndConversation(false);
    setBasis('');
    setIsRecommendationProvided({ riasec: '', strand: '' });
};

const handleRiasecRecommendation = async riasecScores => {
    const sortRiasec = Object.entries(riasecScores).sort(([, a], [, b]) => b - a);
    console.log('\nsort riasec = ', sortRiasec);
    const sameScore = sortRiasec.filter(el => sortRiasec[0][1] === el[1]);
    console.log('sameScore = ', sameScore);

    // sort the riasec
    // get the riasec areas where same as the  highes riasec score
    // if sameScore as highes score is > 3 then randomly select among those riasec areas, else get the top 3 from the sortRiasec
    let RIASEC_CODE = [];

    if (sameScore.length > 3) {
        // mag randomly pick among those highes riasec score as the RIASEC code
        for (let i = 1; i <= 3; i++) {
            const random = Math.floor(Math.random() * sameScore.length); // random index number based on the sameScore.length
            RIASEC_CODE.push(...sameScore.splice(random, 1)); // uses random delete which return a value -> to avoid duplicated value of RIASEC, then stored to new array
        }
    } else RIASEC_CODE = sortRiasec.slice(0, 3);

    console.log('RIASEC CODE = ', RIASEC_CODE);

    setRiasecCode(RIASEC_CODE);
    df_event_query('RIASEC_RECOMMENDATION', RIASEC_CODE);
    setIsRecommendationProvided(prev => ({ ...prev, riasec: 'done' }));
    // fetchCoursesByStrand();
    await fetchRiasecCourses(RIASEC_CODE);

};

const handleRecommendedCourseClick = course => {
    const allMessages = messages;
    let userSays = {
        speaks: 'user',
        msg: {
            text: {
            text: course,
            },
        },
    };

    // remove quick reply message
    messages.pop();
    setMessages([...allMessages, userSays]);
    if (!knownCourses.includes(course)) setKnownCourses(prev => [...prev, course]);

    if (basis === 'riasec') df_event_query('GET_RIASEC_RECOMMENDATION_COURSE_INFO', { course_to_lookup: course });
    else if (basis === 'strand') df_event_query('GET_STRAND_RECOMMENDATION_COURSE_INFO', { course_to_lookup: course });
};

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        console.error('No refresh token found.');
        return null;
    }

    try {
        // Update the endpoint to match your Django backend
        const response = await api.post('/api/token/refresh/', { refresh: refreshToken });
        const { access } = response.data;

        if (access) {
            localStorage.setItem('authToken', access);
            console.log('Access token refreshed successfully.');
            return access;
        } else {
            console.error('No access token returned during refresh.');
            return null;
        }
    } catch (error) {
        console.error('Token refresh failed:', error.message);
        return null;
    }
};
const MAX_RETRY_ATTEMPTS = 3;

const savedConversation = async (
  user,
  riasecCode = [],
  riasecCourses = [],
  strandCourses = [],
  riasecScores = {},
  attempt = 1
) => {
  // Get the token and determine if the user is a guest
  const token = localStorage.getItem('authToken');
  const isGuest = !token;

  // Ensure user details exist before saving for guests
  const safeUser = {
    name: user?.name ? titleCase(user.name) : 'Guest',
    age: isGuest && user?.age ? user.age : null, // Only include age for guests
    sex: user?.sex || 'N/A',
    strand: user?.strand || 'N/A',
  };

  // Prepare the request body
  const body = {
    name: safeUser.name,
    age: isGuest ? safeUser.age : undefined,
    sex: safeUser.sex,
    strand: safeUser.strand,
    riasec_code: riasecCode,
    riasec_course_recommendation: riasecCourses,
    strand_course_recommendation: strandCourses,
    realistic_score: riasecScores.realistic || 0,
    investigative_score: riasecScores.investigative || 0,
    artistic_score: riasecScores.artistic || 0,
    social_score: riasecScores.social || 0,
    enterprising_score: riasecScores.enterprising || 0,
    conventional_score: riasecScores.conventional || 0,
  };

  console.log("📝 Preparing to save conversation:", body);

  try {
    // Select correct API endpoint
    const endpoint = isGuest ? '/api/save-guest-conversation/' : '/api/save-conversation/';
    const headers = isGuest ? {} : { Authorization: `Bearer ${token}` };

    // Send the request
    const response = await api.post(endpoint, body, { headers });

    if (response.status === 201) {
      console.log('✅ Conversation saved successfully:', response.data.message);

      // If user is logged in, increment chatbot starts
      if (!isGuest) {
        await incrementChatbotStarts();
      }

      // Inform the user the conversation was saved
      setMessages(prev => [
        ...prev,
        {
          speaks: 'bot',
          msg: { text: { text: "✅ Your conversation has been saved in your results page 😊. Let me know if you need any more help!" } },
        },
        {
          speaks: 'bot',
          msg: { text: { text: "⭐ How would you rate this conversation?" } },
        },
      ]);

      // Show feedback modal
      setShowFeedbackModal(true);
    } else {
      console.error('❌ Failed to save conversation:', response);
    }
  } catch (error) {
    // If token expired, try refreshing it and retry saving once
    if (error.response?.status === 401 && token) {
      console.warn('⚠️ Access token expired, attempting to refresh...');

      const newToken = await refreshAccessToken();
      if (!newToken) {
        console.error('❌ Failed to refresh access token.');
        return;
      }

      // Retry saving the conversation with the refreshed token
      try {
        const retryResponse = await api.post('/api/save-conversation/', body, {
          headers: { Authorization: `Bearer ${newToken}` },
        });

        console.log('✅ Conversation saved successfully after token refresh:', retryResponse.data.message);
        await incrementChatbotStarts();

        setMessages(prev => [
          ...prev,
          {
            speaks: 'bot',
            msg: { text: { text: "💬 Thank you for chatting with me! 😊 How would you rate this conversation?" } },
          },
        ]);

        setShowFeedbackModal(true);
      } catch (retryError) {
        console.error('❌ Error saving conversation after token refresh:', retryError.response?.data || retryError.message);
      }
    } else if (attempt < MAX_RETRY_ATTEMPTS) {
      console.warn(`Retry attempt ${attempt + 1} for saving conversation...`);
      // Wait for 2 seconds before retrying
      await new Promise(resolve => setTimeout(resolve, 2000));
      return savedConversation(user, riasecCode, riasecCourses, strandCourses, riasecScores, attempt + 1);
    } else {
      console.error('❌ Error saving conversation after maximum retry attempts:', error.response?.data || error.message);
    }
  }
};

  
const handleExpand = (category) => {
    setExpandedCourses(prev => ({
        ...prev,
        [category]: (prev[category] || 5) + 5 // Increase by 5 per click
    }));
};const toggleCourseVisibility = () => {
    setVisibleCourses(prev =>
        prev >= MAX_VISIBLE_COURSES ? DEFAULT_VISIBLE_COURSES : prev + 10
    );
};

const incrementChatbotStarts = async () => {
try {
    const token = localStorage.getItem('authToken');
    const response = await api.patch(
        '/api/increment-chatbot-starts/',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );

    if (response.status === 200) {
        console.log('Chatbot starts incremented:', response.data.chatbot_starts);
    } else {
        console.warn('Unable to increment chatbot starts:', response.data.error);
    }
} catch (error) {
    console.error('Error incrementing chatbot starts:', error.response?.data || error.message);
}
};


const renderCards = cards => {
    return cards.map((card, i) => <Card key={i} payload={card.structValue} />);
};

const renderMessage = (message, i) => {
    if (message.msg && message.msg.text && message.msg.text.text) {
        return (
            <Message key={i} keyword={message.keyword} terms={message.terms && message.terms} speaks={message.speaks} text={message.msg.text.text} />
        );
    } else if (message.msg && message.msg.payload.fields.cards) {
        return (
            <Fragment key={i}>
            <div className='message-cards'>
                <img className='chatbot-avatar message-avatar' src={chatbotAvatar} alt='chathead' />
                <div className='cards'>
                    <div style={{ width: message.msg.payload.fields.cards.listValue.values.length * 270 }}>
                        {renderCards(message.msg.payload.fields.cards.listValue.values)}
                    </div>
                </div>
            </div>
            {message.msg.payload.fields.quick_replies && (
                <QuickReplies
                    triggerCourseOptionYes={triggerCourseOptionYes}
                    clearCourseOptionsYes={clearCourseOptionsYes}
                    isCardQuickReplies={message.msg.payload.fields.cards ? true : false}
                    messages={messages}
                    setMessages={setMessages}
                    replyClick={handleQuickReplyPayload}
                    payload={message.msg.payload.fields.quick_replies.listValue.values}
                />
            )}
            </Fragment>
        );
    } else if (
        message.msg &&
        message.msg.payload &&
        message.msg.payload.fields &&
        message.msg.payload.fields.quick_replies &&
        message.msg.payload.fields.basis &&
        message.msg.payload.fields.recommended_courses_info
    ) {
        return (
            <RecommendedCoursesQuickReply
            key={i}
            payload={message.msg.payload.fields.quick_replies.listValue.values}
            basis={message.msg.payload.fields.basis.stringValue}
            />
        );
    } else if (message.msg && message.msg.payload && message.msg.payload.fields && message.msg.payload.fields.quick_replies) {
        return (
            <QuickReplies
            key={i}
            messages={messages}
            setMessages={setMessages}
            replyClick={handleQuickReplyPayload}
            isRiasecQuickReplies={message.msg.payload.fields.isriasec_quick_replies && message.msg.payload.fields.isriasec_quick_replies.boolValue}
            payload={message.msg.payload.fields.quick_replies.listValue.values}
            />
        );
    } else if (
        message.msg &&
        message.msg.payload &&
        message.msg.payload.fields &&
        message.msg.payload.fields.basis &&
        (message.msg.payload.fields.riasec_recommended_courses || message.msg.payload.fields.strand_recommended_courses)
    ) {
        return (
            <RecommendedCoursesMessage
            key={i}
            speaks={message.speaks}
            isRecommendationProvided
            handleMessagesScrollToBottom={handleMessagesScrollToBottom}
            dialogflowEventQuery={df_event_query}
            setTextMessage={setTextMessage}
            strand={user.strand}
            basis={message.msg.payload.fields.basis.stringValue}
            riasecCourses={
              message.msg.payload.fields.basis.stringValue === 'riasec'
                ? riasecCourses  // e.g. { artistic: [...], conventional: [...], realistic: [...] }
                : {}
            }
            recommendedCourses={
              message.msg.payload.fields.basis.stringValue === 'strand'
                ? message.msg.payload.fields.strand_recommended_courses.listValue.values
                : []
            }
            riasecCode={
              message.msg.payload.fields.basis.stringValue === 'riasec'
                ? riasecCode
                : []
            }
            language={language}
          />
          
          


    );
}
};
const renderMessages = messages => {
    if (messages && messages.length > 0) {
        return messages.map((message, i) => {
            return renderMessage(message, i);
        });
    } else return null;
};

const send = e => {
    e.preventDefault();
    df_text_query(textMessage);
    setTextMessage('');
};

const handleMessagesScrollToBottom = () => {
    // element.scrollTop = element.scrollHeight - element is the container of message
    // for automatic scoll when new message -> messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    // for smooth scrolling, added scroll-behavior: smooth in css for chatbot-messaes class
    // if (messagesRef.current) messagesRef.current.scrollIntoView({ behavior: 'smooth' });
    if (messagesRef.current) messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
};

const removeQuickRepliesAfterType = (messages, setMessages) => {
    const allMessages = messages;
    messages.pop();
    setMessages(allMessages);
};

const handleQuickReplyPayload = (e, payload, text) => {
    e.preventDefault(); // will only work for <a> tag or buttons submit
    e.stopPropagation(); // will only work for <a> tag or buttons submit

    let humanSays = {
        speaks: 'user',
        msg: {
            text: {
            text: text,
            },
        },
    };

    switch (payload) {
        case 'COURSE_OPTIONS_YES':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('COURSE_OPTIONS_YES');
            break;

        case 'RIASEC_START':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('RIASEC_START');
            break;

        case 'ISLEARN_RIASEC_RECOMMENDED_COURSES_YES':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('ISLEARN_RIASEC_RECOMMENDED_COURSES_YES');
            break;

        case 'ISWANT_STRAND_RECOMMENDATION':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('ISWANT_STRAND_RECOMMENDATION');
            break;

        case 'ISLEARN_STRAND_RECOMMENDED_COURSES_YES':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('ISLEARN_STRAND_RECOMMENDED_COURSES_YES');
            break;

        case 'END_CONVERSATION':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('END_CONVERSATION');
            break;

        default:
            df_text_query(text);
            break;
    }
};

const handleResolveAfterXSeconds = x => {
    setBotChatLoading(true);
    return new Promise(resolve => {
        setTimeout(() => {
            setBotChatLoading(false);
            resolve(x);
        }, x * 1000);
    });
};

const handleTermsConditionAgree = async () => {
    setIsAgreeTermsConditions(true); // Set agreement to true
  
    try {
      // Get tokens from localStorage
      let accessToken = localStorage.getItem("authToken");
      const refreshToken = localStorage.getItem("refreshToken");
  
      if (!accessToken) {
        console.warn("No access token found. Proceeding as guest.");
        df_event_query("Welcome"); // Trigger Welcome intent for guests
        return;
      }
  
      // Function to check login status
      const checkLoginStatus = async (token) => {
        return await api.get("/api/check_login_status/", {
          headers: { Authorization: `Bearer ${token}` },
        });
      };
  
      // Function to check chatbot starts limit
      const checkMaxChatbotStarts = async (token) => {
        const response = await api.get("/api/check-max-chatbot-starts/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        return response;
      };
  
      let response = await checkLoginStatus(accessToken);
  
      // Refresh token if access token is invalid
      if (response.status === 401 && refreshToken) {
        const refreshResponse = await api.post("/api/token/refresh/", { refresh: refreshToken });
  
        if (refreshResponse.status === 200) {
          const refreshData = refreshResponse.data;
          accessToken = refreshData.access;
          localStorage.setItem("authToken", accessToken);
          response = await checkLoginStatus(accessToken); // Retry with new token
        } else {
          console.warn("Failed to refresh token. Proceeding as guest.");
          localStorage.removeItem("authToken");
          localStorage.removeItem("refreshToken");
          df_event_query("Welcome"); // Trigger Welcome intent for guests
          return;
        }
      }
  
      if (response.status === 200 && response.data.is_logged_in) {
        const data = response.data;
  
        // Determine the effective deadline:
        // Use the individual extended deadline if available; otherwise, use the global deadline.
        const effectiveDeadline = data.user_data.extended_deadline || data.global_deadline;
        if (effectiveDeadline && new Date() > new Date(effectiveDeadline)) {
          // Deadline has passed: show a message and disable input
          setMessages((prev) => [
            ...prev,
            {
              speaks: "bot",
              msg: {
                text: {
                  text:
                    language === "fil"
                      ? "Ang deadline para sa paggamit ng chatbot ay lumipas na. Pakikontak ang guidance office para sa karagdagang tulong."
                      : "The deadline to use the chatbot has passed. Please contact the guidance office for further assistance.",
                },
              },
            },
          ]);
          setDisabledInput(true);
          setIsVisibleInput(false);
          return; // Stop further processing
        }
  
        // Check if the chatbot starts limit is reached
        const limitResponse = await checkMaxChatbotStarts(accessToken);
        if (limitResponse.status === 200 && limitResponse.data.is_limit_exceeded) {
          console.log("Max chatbot starts reached.");
  
          const limitReachedText =
            language === "fil"
              ? `Kumusta ${data.user_data.name}, naabot mo na ang maximum na bilang ng chatbot sessions. Mangyaring makipag-ugnayan sa guidance office para sa karagdagang tulong.`
              : `Hello ${data.user_data.name}, you have reached the maximum number of chatbot sessions allowed. Please contact the guidance office for further assistance.`;
  
          const limitReachedMessage = {
            speaks: "bot",
            msg: { text: { text: limitReachedText } },
          };
  
          setMessages((prev) => [...prev, limitReachedMessage]);
          setDisabledInput(true);
        } else {
          const welcomeMessageText =
            language === "fil"
              ? `Kumusta ${data.user_data.name}! Maligayang pagbabalik 😊. Simulan na natin ang iyong RIASEC test.`
              : `Hello ${data.user_data.name}! Welcome 😊 I'm Anna, and I'm here to assist you. Let's start your RIASEC test!`;
  
          const welcomeMessage = {
            speaks: "bot",
            msg: { text: { text: welcomeMessageText } },
          };
  
          setUser({
            name: data.user_data.name,
            age: data.user_data.age,
            sex: data.user_data.sex,
            strand: data.user_data.strand,
          });
  
          setMessages((prev) => [...prev, welcomeMessage]);
  
          // Trigger RIASEC start event
          df_event_query("RIASEC_START");
        }
      } else {
        console.warn("User not logged in. Proceeding as guest.");
        df_event_query("Welcome"); // Proceed as a guest
      }
    } catch (error) {
      console.error("Error checking user login status:", error.message);
  
      const errorMessageText =
        language === "fil"
          ? "Nagkaroon ng problema habang sinusuri ang iyong access. Pakisubukang muli mamaya."
          : "Something went wrong while checking your access. Please try again later.";
  
      const errorMessage = {
        speaks: "bot",
        msg: { text: { text: errorMessageText } },
      };
  
      setMessages((prev) => [...prev, errorMessage]);
    }
  };
  



// const fetchCoursesByStrand = async () => {
//    try {
//       const response = await fetch(`/user/courses-by-strand/${user.strand}`);
//       const data = await response.json();
//       console.log(data);
//       if (response.status === 200) setStrandBasedRecommendedCourses(data.courses);
//    } catch (err) {
//       console.error(err.message);
//    }
// };

useEffect(() => {
    console.log("Messages in Chatbot State:", messages);
    handleMessagesScrollToBottom();
}, [messages, showBot]);

useEffect(() => {
    if (cookies.get('termsCondition') !== '' && cookies.get('termsCondition') !== 'false') setIsAgreeTermsConditions(false);
    else setIsAgreeTermsConditions(true);
}, []);
useEffect(() => { 
    setMessages((prevMessages) => {
        return prevMessages.map((message) =>
            message.keyword === 'terms-conditions'
                ? {
                    ...message,
                    msg: {
                        text: {
                            text: language === 'fil'
                                ? 'Kumusta. Bago tayo magsimula, sa session na ito ay kukunin ko ang iyong pangunahing impormasyon. Kailangan mo munang basahin at sumang-ayon sa mga tuntunin na ipinakita sa'
                                : 'Hello. Before we begin, in this session I will take your basic information. You must first read and agree on the terms presented in the',
                        },
                    },
                }
                : message
        );
    });
}, [language]); // Runs when language changes

return (
    <>
        {showBot ? (
            <div className='chatbot shadow'>
            {/* chatbot header */}
{/* Chatbot Header */}
<div className="chatbot-header d-flex justify-content-between align-items-center bg-primary p-2">
<div className="d-flex align-items-center">
    <img className="chatbot-avatar" src={chathead} alt="chathead" />
    <h2 className="ms-2 h6 d-inline custom-heading text-white">Anna</h2>
</div>

<div className="d-flex align-items-center">
    {/* Language Dropdown */}
    <Dropdown>
    <Dropdown.Toggle variant="light" className="language-dropdown">
        <IoLanguageOutline size={24} className="white-icon" />
        <span className="ms-2">Language</span>
    </Dropdown.Toggle>

    <Dropdown.Menu align="center">
        <Dropdown.Item
        onClick={() => switchLanguage('en')}
        active={language === 'en'}
        >
        English
        </Dropdown.Item>
        <Dropdown.Item
        onClick={() => switchLanguage('fil')}
        active={language === 'fil'}
        >
        Filipino (Beta)
        </Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>

    {/* Close Button */}
    <MdClose 
    className="chatbot-close ms-3 white-icon" 
    size={24} 
    onClick={() => setShowbot(false)} 
    />
</div>
</div>


            {/* chatbot messages */}
            <div ref={messagesRef} className='chatbot-messages'>
                {/* <button className='btn btn-primary' onClick={() => handleRiasecRecommendation(riasec)}>
                    Identify RIASEC Area
                </button> */}

                {renderMessages(messages)}
                {/* <div ref={messageEnd}></div> */}
                {botChatLoading && (
                    <div className='message bot'>
                        <div>
                        <img className='chatbot-avatar message-avatar' src={chatbotAvatar} alt='chathead' />
                        </div>
                        <div className='message-text bot'>
                        <img className='message-loading' src={chatloading} alt='loading' />
                        </div>
                    </div>
                )}
            </div>
            {/* text-input */}
            <form className='chatbot-text-input' onSubmit={send}>
                <input
                    ref={inputRef}
                    className={`${isVisibleInput ? 'visible' : 'invisible'}`}
                    disabled={!isAgreeTermsConditions || disabledInput ? true : false}
                    value={textMessage}
                    type='text'
                    placeholder='Your answer here...'
                    onChange={e => setTextMessage(e.target.value)}
                />
                <button className='btn p-0 chatbot-send' disabled={!textMessage ? true : false} type='submit'>
                    <MdSend className={`chatbot-send text-primary ${isVisibleInput ? 'visible' : 'invisible'}`} />
                </button>
            </form>
            </div>
        ) : (
            <div className='chathead-container'>
            <div className='chathead-message'>Hi! Chat with me 😊</div>
            <img className='chathead' src={chathead} alt='chathead' onClick={() => setShowbot(true)} />
            </div>
        )}

    {/* terms & conditions modal */}
<Modal title='Terms and Conditions' target='modal-terms-conditions' size='modal-lg'>
<div className='p-2'>
    <p>In using Anna, you agree to these terms and conditions:</p>
    <ol className='m-0' type='A'>
        <li>All responses and correspondences with Anna will be recorded.</li>
        <li>
            Information such as name (required), age (required), sex (required), senior high school strand (required), and related
            correspondence will be for the exclusive use of this study to continuously improve Anna.
        </li>
        <li>The data collected will be used for as long as it is needed for further analysis or investigation.</li>
        <li>You are free to exit the conversation with Anna if you feel the need to do so.</li>
    </ol>
</div>

<div className='p-2'>
    <h1 className='h5 custom-heading text-primary'>USER GUIDELINES:</h1>
    <p>Anna could only converse in the English language. It is then recommended that your responses be in English.</p>
    <p>
        If the user is idle for more than 20 minutes, Anna would end the conversation by replying with phrases like, "I think I lost you
        there. Please do reach out to me again anytime. I'll be here 😊". If this happens, greeting Anna with words like "Hello", or "Hi",
        will start a new conversation.
    </p>
    <p className='mb-1'>
        If any problems occur during the conversation process, or you have any suggestions or comments you would like to share with the
        researchers, please leave a feedback
        <a className='text-primary ms-1' href='/#feedback'>
            here
        </a>
        . Your insights and suggestions would help improve our project.
    </p>
</div>

<div className='p-2'>
    <h1 className='h5 custom-heading text-primary'>CONFIDENTIALITY</h1>
    <p>
        The information that Anna will be obtaining throughout the conversation will remain confidential to protect your rights or welfare.
    </p>
    <p>
        RA 10173 or the Data Privacy Act protects individuals from unauthorized processing of personal information. To ensure that your
        information is protected, the researchers will follow this law to keep your information safe and confidential.
    </p>
</div>

<div className='p-2'>
    <h1 className='h5 custom-heading text-primary'>DEFINITIONS</h1>
    <p>
        Throughout the conversation, Anna will be responding to possible jargons. To ensure that you understand Anna, the definition of
        words will be provided:
    </p>
    <p className='mb-1'>
        <span className='fw-bold'>Degree Program</span> - A class that a college or university offers to students. (Bachelor of Science in
        Information Technology, etc..)
    </p>
    <p className='mb-1'>
        <span className='fw-bold'>RIASEC</span> - A personality test that asks about your interest, skills, ability, and aspirations which
        will help you decide on what career to pursue based on these attributes.
    </p>
    <p className='mb-1'>
        <span className='fw-bold'>Senior high school strand</span> - Disciplines that are offered by schools to senior high school students
        that would prepare them for college.
    </p>
</div>

{!isAgreeTermsConditions && (
    <div className='form-check m-2'>
        <input
            className='form-check-input'
            onChange={() => handleTermsConditionAgree()}
            type='checkbox'
            value=''
            id='terms-conditions-check'
        />
        <label className='form-check-label fw-bold' htmlFor='terms-conditions-check'>
            I Agree to the Terms and Conditions
        </label>
    </div>
)}

<div className='mt-3 float-end'>
    <button className='btn btn-primary' data-bs-dismiss='modal'>
        Close
    </button>
</div>
</Modal>
<Modal
  title={`${basis === 'riasec' ? 'RIASEC' : 'Strand'} | Recommended Degree Programs`}
  target='modal-recommended-courses-info'
  size='modal-lg'
>
  <div className='d-flex flex-column'>
    {/* Improved Search Input */}
    <div className="mb-4 px-3">
      <div className="search-input-container position-relative">
        <Form.Control
          type="search"
          placeholder={language === 'fil' ? "Hanapin ang kurso..." : "Search for a course..."}
          value={courseSearchTerm}
          onChange={(e) => setCourseSearchTerm(e.target.value)}
          className="search-input form-control-lg ps-5 pe-4 rounded-pill shadow-sm border-0"
          style={{
            backgroundColor: '#f8f9fa',
            color: '#495057',
            fontSize: '1rem',
            height: '48px'
          }}
        />
        <div className="search-icon position-absolute top-50 start-0 translate-middle-y ms-3">
          <FaSearch className="text-muted" />
        </div>
      </div>
    </div>

    {basis === 'riasec' ? (
      riasecBasedRecommendedCourses.length > 0 && (
        <>
          {/* Top 3 RIASEC Headers - Properly aligned */}
          <Row className="mb-3 text-center d-none d-md-flex px-3">
            {riasecCode.slice(0, 3).map(([category], index) => (
              <Col key={index} md={4} className="mb-2">
                <h4 className="text-primary fw-bold">{category.toUpperCase()}</h4>
              </Col>
            ))}
          </Row>

          {/* Full Course List per Category with Animation */}
          <Row className="px-3">
            {riasecCode.slice(0, 3).map(([category], index) => {
              // Filter courses for the current category based on the search term
              const filteredCourses = riasecCourses[category]?.filter(course => {
                const courseName = typeof course === 'string'
                  ? course
                  : course.name || course.stringValue || '';
                return courseName.toLowerCase().includes(courseSearchTerm.toLowerCase());
              });
              return (
                <Col key={index} xs={12} md={4} className="mb-4">
                  <h4 className="text-primary text-center d-md-none mb-3">{category.toUpperCase()}</h4>
                  <ListGroup
                    as={motion.div}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.5 }}
                  >
                    {filteredCourses
                      ?.slice(0, visibleCourses)
                      .map((course, i) => (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3, delay: i * 0.05 }}
                        >
                          <ListGroup.Item
                            action
                            className="d-flex align-items-center"
                            data-bs-dismiss='modal'
                            onClick={() => handleRecommendedCourseClick(course.name)}
                          >
                            <FaUserGraduate className="me-3 text-muted" />
                            <span className="flex-grow-1">{course.name}</span>
                          </ListGroup.Item>
                        </motion.div>
                      ))}
                  </ListGroup>
                </Col>
              );
            })}
          </Row>

          {/* "See More" / "See Less" Button */}
          <div className="text-center mt-3 mb-3">
            <Button
              variant="outline-primary"
              className="rounded-pill px-4"
              onClick={toggleCourseVisibility}
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {visibleCourses >= MAX_VISIBLE_COURSES
                ? (language === 'fil' ? 'Tingnan ang Mas Kaunti' : 'See Less')
                : (language === 'fil' ? 'Tingnan ang Higit Pa' : 'See More')}
            </Button>
          </div>
        </>
      )
    ) : (
      strandBasedRecommendedCourses.length > 0 && (
        <>
          <Row className="mb-3 text-center">
            <Col xs={12}>
              <h4 className="text-primary fw-bold">Strand-Based Recommendations</h4>
            </Col>
          </Row>

          <motion.div
            className="px-3"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.5 }}
          >
            {strandBasedRecommendedCourses
              .filter(course => {
                const courseName = typeof course === 'object'
                  ? course.name || course.stringValue || ''
                  : course;
                return courseName.toLowerCase().includes(courseSearchTerm.toLowerCase());
              })
              .slice(0, visibleCourses)
              .map((course, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: i * 0.05 }}
                >
                  <div
                    className={`course-recommendation border-bottom py-2 d-flex align-items-center ${knownCourses.includes(course) ? 'active' : ''}`}
                    data-bs-dismiss='modal'
                    onClick={() => handleRecommendedCourseClick(course)}
                  >
                    <FaUserGraduate className='me-3 text-muted' />
                    <span className="flex-grow-1">{course}</span>
                  </div>
                </motion.div>
              ))}
          </motion.div>

          {/* "See More" / "See Less" Button */}
          <div className="text-center mt-3 mb-3">
            <Button
              variant="outline-primary"
              className="rounded-pill px-4"
              onClick={toggleCourseVisibility}
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {visibleCourses >= MAX_VISIBLE_COURSES
                ? (language === 'fil' ? 'Tingnan ang Mas Kaunti' : 'See Less')
                : (language === 'fil' ? 'Tingnan ang Higit Pa' : 'See More')}
            </Button>
          </div>
        </>
      )
    )}
  </div>
</Modal>

        {/* Feedback Modal */}
        {showFeedbackModal && (
<FeedbackModal 
    show={showFeedbackModal} 
    onClose={() => setShowFeedbackModal(false)} 
    isLoggedIn={isLoggedIn} 
    target="feedback-modal"
    category="chatbot" // Specify that this feedback is from the chatbot
/>
)}

    </>
);
};

export default Chatbot;