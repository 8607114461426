    import React, { useEffect, useState, useRef, useContext} from 'react';
    import { useNavigate } from 'react-router-dom';
    import { toast } from 'react-toastify';
    import 'bootstrap/dist/css/bootstrap.min.css';
    import api from '../../utils/api';
    import { TailSpin } from 'react-loader-spinner';
    import Navbar from '../pages/Navbar'; // Import Navbar
    import jsPDF from 'jspdf'; // Import jsPDF
    import html2canvas from 'html2canvas'; // Import html2canvas
    import apiNode from '../../components/chatbot/apiNode'; // Import Node.js API
    import '../../styles/Results.css'
    import { motion } from 'framer-motion';
    import { UserContext } from '../../context/UserContext'; // if available

    const Results = () => {
      const [conversationData, setConversationData] = useState(null);
      const [loading, setLoading] = useState(true);
      const [selectedCourse, setSelectedCourse] = useState(null); // Store selected course details
      const [showModal, setShowModal] = useState(false); // Control modal visibility
      const [courseCache, setCourseCache] = useState({});
      const [loadingCourse, setLoadingCourse] = useState(false); // Track course loading
      const [showAllRiasec, setShowAllRiasec] = useState(false);
      const [showAllStrand, setShowAllStrand] = useState(false);
      const navigate = useNavigate();

      const { user } = useContext(UserContext);  
      const [isStudent, setIsStudent] = useState(true);
   
      const resultsRef = useRef(null);
      // Fetch student result data
      useEffect(() => {
        if (!loading && conversationData) {
          requestAnimationFrame(() => {
            window.scrollTo(0, 0); // Reset to top
            document.body.style.overflow = 'auto'; // Ensure scrolling is enabled
          });
        }
      }, [loading, conversationData]);
      
      
    // Fetch student result data
  useEffect(() => {
    const fetchResults = async () => {
      try {
        // Skip fetch for staff/superuser users
        if (user?.is_staff || user?.is_superuser) {
          setLoading(false);
          return;
        }

        const token = localStorage.getItem('authToken');
        if (!token) throw new Error('No auth token found');
  
        const response = await api.get('/auth/get-conversations/', {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.data.conversations?.length > 0) {
          setConversationData(response.data.conversations[0]);
        } else {
          setConversationData(null);
        }
      } catch (error) {
        console.error('Error fetching conversation data:', error);
        toast.error('Failed to fetch results');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchResults();
    } else {
      setLoading(false); // Make sure to set loading to false if no user
    }
  }, [user]);
      
      
      const fetchCourseDetails = async (courseName) => {
        setShowModal(true); // ✅ Open modal first to show loading state
        setLoadingCourse(true); // ✅ Show loading spinner

        if (courseCache[courseName]) {
            setSelectedCourse(courseCache[courseName]);
            setLoadingCourse(false); // ✅ Stop loading since data is cached
            return;
        }

        try {
            const formattedCourseName = encodeURIComponent(courseName.trim());
            console.log(`Fetching course: ${formattedCourseName}`);

            const response = await apiNode.get(`/api/courses?name=${formattedCourseName}`);

            if (response.data) {
                setCourseCache(prevCache => ({ ...prevCache, [courseName]: response.data })); // ✅ Cache fetched course
                setSelectedCourse(response.data);
            }
        } catch (error) {
            console.error('Error fetching course details:', error);
            toast.error('Failed to load course details');
        } finally {
            setLoadingCourse(false); // ✅ Hide loading spinner
        }
    };





      
      // PDF Download Function
      const downloadAsPDF = async () => {
        const element = resultsRef.current; // Get the results container

        // Capture the full height of the content
        const canvas = await html2canvas(element, {
            scale: 2, // Increase quality
            useCORS: true, // Handle external images
            scrollX: 0,
            scrollY: -window.scrollY, // Ensure full page capture
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.scrollHeight,
        });

        const imgData = canvas.toDataURL('image/png'); // Convert canvas to image
        const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait mode, A4 size

        let imgWidth = pdf.internal.pageSize.getWidth();
        let imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale proportionally

        if (imgHeight > pdf.internal.pageSize.getHeight()) {
            // If content is larger than one page, add new pages
            let yPosition = 0;
            while (yPosition < imgHeight) {
                pdf.addImage(imgData, 'PNG', 0, yPosition * -1, imgWidth, imgHeight);
                yPosition += pdf.internal.pageSize.getHeight();
                if (yPosition < imgHeight) pdf.addPage(); // Add new page when needed
            }
        } else {
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        }

        pdf.save('results.pdf'); // Save the PDF file
    };



      // Extract the top 20 RIASEC and Strand recommendations
      const getTopRecommendations = (recommendations) => recommendations.slice(0, 20);

      if (loading) {
        return (
          <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <TailSpin height={80} width={80} color="#ffcc00" />
            <p>Loading results...</p>
          </div>
        );
      }
      
          // In the Results component's return section
      // Add this before any data rendering logic
    if (!user || user?.is_staff || user?.is_superuser) {
      return (
        <div className="container vh-100 d-flex flex-column align-items-center justify-content-center">
          <Navbar />
          <div className="alert alert-warning text-center">
            <i className="bi bi-person-x fs-4 me-2"></i>
            Please log in to a student account to view results
          </div>
        </div>
      );
  }
            
      
      if (!conversationData) {
        return (
          <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
            <i className="bi bi-exclamation-circle" style={{ fontSize: '5rem', color: '#ffcc00' }}></i>
            <h2 className="mt-3" style={{ color: '#555' }}>No Results Found</h2>
            <p className="text-muted">
              We couldn't find any matching results. Please try again later or go back to check other options.
            </p>
            <button
              className="btn btn-warning mt-3"
              style={{ fontSize: '1rem', padding: '10px 20px' }}
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        );
      }

      const riasecRecommendations = conversationData.riasec_course_recommendation;
      const topStrand = getTopRecommendations(conversationData.strand_course_recommendation);

      return (
        <div className="container-fluid p-0">
          {/* Navbar */}
          <Navbar />
    {/* Floating PDF Download Button */}
    <div
      className="position-fixed"
      style={{
        bottom: '20px',
        right: '20px',
        zIndex: 1050,
      }}
    >
      <button
        className="btn btn-primary rounded-circle shadow"
        onClick={downloadAsPDF}
        style={{
          width: '60px',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <i className="bi bi-arrow-down-circle"></i>

      </button>
    </div>



          {/* Main Content */}
          <div
            ref={resultsRef} // Attach the ref to this container
            className="container mt-3 p-4 shadow-sm bg-light rounded"
          >
            {/* Centered Student Information Section */}
            <div className="row mb-5 justify-content-center text-center">
              <div className="col-md-2">
                <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Student Name</strong>
                <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.name}</p>
              </div>
              <div className="col-md-2">
                <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Sex</strong>
                <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.sex}</p>
              </div>
              <div className="col-md-2">
                <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Strand</strong>
                <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.strand}</p>
              </div>
              <div className="col-md-2">
                <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Test Taken Date</strong>
                <p className="text-muted" style={{ fontSize: '1rem' }}>
                  {new Date(conversationData.created_at).toLocaleDateString()}
                </p>
              </div>
            </div>

            {/* RIASEC Code */}
            <div className="row text-center mb-4 d-flex justify-content-center">
              {conversationData.riasec_code.map((code, index) => (
                <div key={index} className="col-md-2 col-4 mb-3">
                  <div className="bg-warning text-white rounded py-4">
                    <strong className="d-block">{code[0].toUpperCase()}</strong>
                    <span className="fs-4">{code[1]}</span>
                  </div>
                </div>
              ))}
            </div>

            {/* Suggestions */}
    <div className="suggestions mt-4">
      <h4 className="text-center mb-4">ANNA RECOMMENDATIONS</h4>

      <div className="row">
        {/* RIASEC Courses */}
        <div className="col-md-6 mb-3">
    <h5 className="text-center">Top RIASEC Course Recommendations</h5>
    {riasecRecommendations && Object.keys(riasecRecommendations).length > 0 ? (
      Object.entries(riasecRecommendations).map(([category, courses]) => (
        <div key={category} className="mb-3">
          <h6 className="text-center">{category.toUpperCase()}</h6>
          <div className="card border-0 shadow-sm">
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="list-group list-group-flush border rounded"
            >
              {(showAllRiasec ? courses : courses.slice(0, 5)).map((course, index) => (
                <motion.li 
                  key={index}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="list-group-item py-2 course-item"
                  onClick={() => fetchCourseDetails(course.name)}
                >
                  {course.name}
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </div>
      ))
    ) : (
      <p className="text-center text-muted p-3">
        No RIASEC recommendations available at this time.
      </p>
    )}
    <div className="text-center mt-2">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="btn btn-sm btn-outline-primary"
        onClick={() => setShowAllRiasec(prev => !prev)}
      >
        {showAllRiasec ? 'See Less' : 'See More'}
      </motion.button>
    </div>
  </div>



        {/* Strand Courses */}
        <div className="col-md-6 mb-3">
          <h5 className="text-center">Top Strand Course Recommendations</h5>
          <div className="card border-0 shadow-sm">
            {topStrand.length > 0 ? (
              <>
                <ul className="list-group list-group-flush border rounded">
                  {topStrand.slice(0, showAllStrand ? topStrand.length : 5).map((course, index) => (
                    <li 
                      key={index} 
                      className="list-group-item py-2 course-item" 
                      onClick={() => fetchCourseDetails(course)}
                    >
                      {course}
                    </li>
                  ))}
                </ul>
                {topStrand.length > 5 && (
                  <div className="text-center mt-2">
                    <button 
                      className="btn btn-sm btn-outline-primary" 
                      onClick={() => setShowAllStrand(!showAllStrand)}
                    >
                      {showAllStrand ? 'See Less' : 'See More'}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <p className="text-center text-muted p-3">No Strand recommendations available at this time.</p>
            )}
          </div>
        </div>
      </div>
    </div>
        </div>

        {/* 📌 Add the Course Details Modal Right Before This Closing Div */}
    {/* 📌 Course Details Modal with Loading Indicator */}
    {showModal && (
      <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
          
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">
                {loadingCourse ? "Loading course details..." : selectedCourse?.name}
              </h5>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {loadingCourse ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <TailSpin color="#007bff" height={50} width={50} />
                  <p className="mt-3 text-muted">Fetching course details, please wait...</p>
                </div>
              ) : (
                <>
                  <p><strong>Description:</strong> {selectedCourse?.description}</p>
                  <p><strong>RIASEC Areas:</strong> {selectedCourse?.riasec_area.join(', ')}</p>
                  <p><strong>Category:</strong> {selectedCourse?.category || 'N/A'}</p>
                </>
              )}
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
            </div>

          </div>
        </div>
      </div>
    )}


      </div> // 📌 Closing div of the main return statement
    );
    }

    export default Results;
