import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import api from "../../utils/api";
import { toast } from "react-toastify";

const GlobalDeadlineModal = ({ show, onHide, currentDeadline }) => {
  const [newDeadline, setNewDeadline] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  // Format the current deadline nicely.
  const formatDeadline = (deadline) => {
    if (!deadline) return "No deadline set";
    const d = new Date(deadline);
    return d.toLocaleString();
  };

  // When the modal opens, clear the new deadline input and confirmation.
  useEffect(() => {
    if (show) {
      setNewDeadline("");
      setShowConfirm(false);
    }
  }, [show]);

  // When the user clicks "Save" in the main modal, open the confirmation modal.
  const handleSaveClick = () => {
    setShowConfirm(true);
  };

  // When the user confirms, call the API and close all modals.
  const handleConfirm = async () => {
    try {
      await api.patch("/school/set-global-deadline/", {
        global_deadline: newDeadline,
      });
      toast.success("Global deadline updated successfully!", {
        position: "top-right",
      });
      setShowConfirm(false);
      onHide();
    } catch (err) {
      toast.error(err.response?.data?.error || "Error updating deadline.", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Set Global Deadline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display current deadline */}
          <div className="mb-3">
            <strong>Current Deadline:</strong> {formatDeadline(currentDeadline)}
          </div>
          {/* Input for new deadline */}
          <Form.Group>
            <Form.Label>New Deadline (Date & Time)</Form.Label>
            <Form.Control
              type="datetime-local"
              value={newDeadline}
              onChange={(e) => setNewDeadline(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deadline Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to update the global deadline to:{" "}
          <strong>{newDeadline}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GlobalDeadlineModal;
