import '../../styles/chatbot.css';
import { useEffect, useState, useContext } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import chatbotAvatar from '../../assets/Anna_Chat_Avatar.svg';
import chatloading from '../../assets/chatbot-loading.gif';
import { ChatbotContext } from '../../context/ChatbotContext';

const RecommendedCoursesMessage = ({
  speaks,
  // For RIASEC, pass an object of arrays (keys = category names)
  riasecCourses = {},
  // For STRAND-based recommendations, pass an array
  recommendedCourses = [],
  handleMessagesScrollToBottom,
  dialogflowEventQuery,
  setTextMessage,
  basis, // "riasec" or "strand"
  strand,
  language,
  riasecCode = [] // e.g. [['artistic', 6], ['conventional', 5], ['realistic', 4]]
}) => {
  const { language: contextLanguage } = useLanguage();
  const selectedLanguage = language || contextLanguage || 'en';
  const [strandEventSent, setStrandEventSent] = useState(false);
  const [hasDisplayedMessage, setHasDisplayedMessage] = useState(false);

  const {
    isRecommendationProvided,
    setIsRecommendationProvided,
    setDisabledInput,
    setIsVisibleInput,
  } = useContext(ChatbotContext);

  const [isRecommendationLoading, setIsRecommendationLoading] = useState(false);
  const [showAllCourses, setShowAllCourses] = useState(false);
  const [studentStrand] = useState(strand);

  // Introductory text explaining it’s just a preview
  const introductoryText = {
    en: {
      riasec:
        'Based on your interests, here’s a preview of some degree programs that may suit you in college. They are grouped by your strongest RIASEC categories. Let me know if you’d like more details!',
      strand:
        `Since you’re from the ${studentStrand} strand, here’s a preview of recommended degree programs. Let me know if you need more suggestions!`,
    },
    fil: {
      riasec:
        'Batay sa iyong interes, narito ang isang preview ng mga kursong maaaring angkop sa iyo. Pinangkat ang mga ito ayon sa iyong pinakamalakas na RIASEC na mga kategorya. Ipaalam sa akin kung gusto mo pang makita ang iba pang detalye!',
      strand:
        `Dahil ikaw ay mula sa strand na ${studentStrand}, narito ang isang preview ng mga inirerekomendang kurso. Ipaalam sa akin kung kailangan mo pa ng iba pang mungkahi!`,
    },
  };

  useEffect(() => {
    if (
      !hasDisplayedMessage &&
      (isRecommendationProvided.riasec === 'done' ||
        isRecommendationProvided.strand === 'done') &&
      !isRecommendationLoading
    ) {
      setIsRecommendationLoading(true);
      setDisabledInput(true);
      setIsVisibleInput(false);
      setTextMessage('');

      setTimeout(() => {
        setIsRecommendationLoading(false);
        setDisabledInput(false);
        setIsRecommendationProvided(prev => ({
          ...prev,
          riasec: prev.riasec === 'done' ? '' : prev.riasec,
          strand: prev.strand === 'done' ? '' : prev.strand,
        }));
        setHasDisplayedMessage(true);

        if (isRecommendationProvided.riasec === 'done') {
          handleMessagesScrollToBottom();
          dialogflowEventQuery('ISLEARN_RIASEC_RECOMMENDED_COURSES');
        } else if (isRecommendationProvided.strand === 'done') {
          handleMessagesScrollToBottom();
          dialogflowEventQuery('ISLEARN_STRAND_RECOMMENDED_COURSES');
        }
      }, 4000);
    }
  }, [isRecommendationProvided, isRecommendationLoading, hasDisplayedMessage]);

  // Once the message is displayed, hide the duplicate message from the UI

  if (basis === 'riasec') {
    // For RIASEC, we assume riasecCourses is an object like:
    // { artistic: [...], conventional: [...], realistic: [...] }
    // To preserve the order (if available) from your riasecCode array, map over that:
    let categoryOrder = [];
    if (riasecCode && riasecCode.length > 0) {
      categoryOrder = riasecCode.map(([cat]) => cat.toLowerCase());
    } else {
      categoryOrder = Object.keys(riasecCourses);
    }

    return (
      <>
        {!isRecommendationLoading ? (
          <div className={`message ${speaks === 'bot' ? 'bot' : 'user'}`}>
            {speaks === 'bot' && (
              <div>
                <img
                  className="chatbot-avatar message-avatar"
                  src={chatbotAvatar}
                  alt="chathead"
                />
              </div>
            )}
            <div className={`message-text ${speaks === 'bot' ? 'bot' : 'user'}`}>
              <p>
                {introductoryText[selectedLanguage]?.riasec ||
                  introductoryText.en.riasec}
              </p>
              {categoryOrder.map((category, idx) => {
                const courses = riasecCourses[category] || [];
                if (!courses.length) return null;

                // Determine how many courses to show: 4 for top category, 3 for the rest
                const isTopCategory = idx === 0;
                const coursesToShow = isTopCategory ? 4 : 3;
                const displayedCourses = showAllCourses
                  ? courses
                  : courses.slice(0, coursesToShow);

                return (
                  <div key={idx}>
                    <h5 className="text-primary">{category.toUpperCase()}</h5>
                    <ul className="recommendedCourses">
                      {displayedCourses.map((courseObj, i) => {
                        const courseName =
                          typeof courseObj === 'string'
                            ? courseObj
                            : courseObj.name || courseObj.stringValue || '';
                        return <li key={i}>{courseName}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
              <p className="small text-muted mt-2">
                {selectedLanguage === 'fil'
                  ? '📌 Ang mga kurso ay nakalista ayon sa alpabetiko sa bawat RIASEC na kategorya.'
                  : '📌 Courses are listed in alphabetical order within each RIASEC category.'}
              </p>
            </div>
          </div>
        ) : (
          <div className="message bot">
            <div>
              <img
                className="chatbot-avatar message-avatar"
                src={chatbotAvatar}
                alt="chathead"
              />
            </div>
            <div className="message-text bot">
              <img className="message-loading" src={chatloading} alt="loading" />
            </div>
          </div>
        )}
      </>
    );
  } else {
    // For strand-based recommendations
    const coursesToDisplayForStrand = showAllCourses
      ? recommendedCourses
      : recommendedCourses.slice(0, 5);
    return (
      <>
        {!isRecommendationLoading ? (
          <div className={`message ${speaks === 'bot' ? 'bot' : 'user'}`}>
            {speaks === 'bot' && (
              <div>
                <img
                  className="chatbot-avatar message-avatar"
                  src={chatbotAvatar}
                  alt="chathead"
                />
              </div>
            )}
            <div className={`message-text ${speaks === 'bot' ? 'bot' : 'user'}`}>
              <p>
                {introductoryText[selectedLanguage]?.strand ||
                  introductoryText.en.strand}
              </p>
              <ul className="recommendedCourses">
                {coursesToDisplayForStrand.map((course, idx) => {
                  const courseName =
                    typeof course === 'object'
                      ? course.name || course.stringValue
                      : course;
                  return <li key={idx}>{courseName}</li>;
                })}
              </ul>
              {recommendedCourses.length > 5 && (
                <div className="view-more-container text-center mt-2">
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => setShowAllCourses(!showAllCourses)}
                  >
                    {showAllCourses
                      ? selectedLanguage === 'fil'
                        ? 'Tingnan ang Mas Kaunti'
                        : 'See Less'
                      : selectedLanguage === 'fil'
                      ? 'Tingnan ang Higit Pa'
                      : 'See More'}
                  </button>
                </div>
              )}
              <p className="small text-muted mt-2">
                {selectedLanguage === 'fil'
                  ? 'Ang mga kurso ay nakalista ayon sa alpabetiko.'
                  : 'Courses are listed in alphabetical order.'}
              </p>
            </div>
          </div>
        ) : (
          <div className="message bot">
            <div>
              <img
                className="chatbot-avatar message-avatar"
                src={chatbotAvatar}
                alt="chathead"
              />
            </div>
            <div className="message-text bot">
              <img className="message-loading" src={chatloading} alt="loading" />
            </div>
          </div>
        )}
      </>
    );
  }
};

export default RecommendedCoursesMessage;
