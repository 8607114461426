  import React, { useContext, useState, useEffect, useRef } from 'react';
  import { useModalInstance } from 'react-modal-state';
  import { Modal } from 'react-bootstrap';
  import api from '../../utils/api';
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import '../../styles/RegisterModal.css';

  const RegisterModal = () => {
    const [loading, setLoading] = useState(false); // Manage loading state
    const { isOpen, close } = useModalInstance();
    const { open: openLoginModal } = useModalInstance();
    const [formData, setFormData] = useState({
      full_name: '',
      email: '',
      strand: '',
      sex: '',
      walkIn: 'yes',  // Default to 'yes' since only Walk-ins can register
    });
    

    const [sections, setSections] = useState([]);
    const [showVerificationMessage, setShowVerificationMessage] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [birthdayError, setBirthdayError] = useState('');
    const fetchedSchools = useRef(new Set()); // Track fetched schools to avoid redundant API calls
    const [isWalkIn, setIsWalkIn] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Track current page (1 or 2)

    useEffect(() => {
      const fetchSections = async () => {
          if (!formData.school_id || fetchedSchools.current.has(formData.school_id)) return;

          try {
              // Fetch sections without requiring a token
              const response = await api.get(`api/sections/`);
              setSections(response.data);
              fetchedSchools.current.add(formData.school_id); // Mark school as fetched
          } catch (error) {
              console.error('Error fetching sections:', error);
              toast.error('Failed to fetch sections. Please contact support.');
          }
      };

      fetchSections();
  }, [formData.school_id]);

  useEffect(() => {
    const fetchSchoolByHostname = async () => {
        const hostname = window.location.hostname;
        try {
            const response = await api.get(`/schools/${hostname}`);
            if (response.data?.id) {
                setFormData((prevData) => ({ ...prevData, school_id: response.data.id }));
            }
        } catch (error) {
            console.error('Error fetching school:', error);
            toast.error('Failed to fetch school. Please contact support.');
        }
    };

    fetchSchoolByHostname();
}, []);


const handleChange = (e) => {
  const { id, value } = e.target;
  const newValue = id === 'email' ? value.replace(/\s+/g, '').toLowerCase() : value;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [id]: newValue
  }));
};

const handleWalkInToggle = (e) => {
  const value = e.target.value; // 'yes' or 'no'

  setFormData((prevFormData) => ({
    ...prevFormData,
    walkIn: value,  // <-- Store Walk-In selection in formData
    ...(value === 'yes' && { // Reset fields only when 'yes' is selected
      id_no: '',
      mobile_no: '',
      grade_level: '',
      section_id: '',
      birthday: '',
    }),
  }));
};


const handleNext = () => {
  if (!formData.full_name || !formData.email || !formData.strand) {
    toast.error('Please fill in all required fields before proceeding.');
    return;
  }

  if (!isValidEmail(formData.email)) {
    toast.error('Please enter a valid email address.');
    return;
  }

  if (!isWalkIn) {
    setCurrentPage(2);
  }
};


const handleBack = () => setCurrentPage(1);
const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};


    // Password validation function
    const isValidPassword = (password) => {
    // At least one uppercase letter and at least 8 characters long
      const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
      return passwordRegex.test(password);
};

    // Mobile number validation
    const isValidMobile = (mobile) => {
      const mobileRegex = /^[0-9]{10,15}$/;
      return mobileRegex.test(mobile);
    };
    const handleBirthdayChange = (e) => {
      const newBirthday = e.target.value;
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        birthday: newBirthday,
      }));
    
      // Convert the input string to a date object
      const birthDate = new Date(newBirthday);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to compare dates accurately
    
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
    
      // Adjust age if birthday hasn't occurred yet this year
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
    
      if (birthDate > today) {
        setBirthdayError('Birthday cannot be in the future.');
      } else if (age < 10 || age > 100) {
        setBirthdayError('Valid age range: 10 to 100 years old.');
      } else {
        setBirthdayError('');
      }
    };
    
    
    
    
    // Birthday validation
    const isValidBirthday = (birthday) => {
      if (!birthday) return false;  // ✅ Ensure it's not empty
    
      const birthDate = new Date(birthday);
      if (isNaN(birthDate.getTime())) return false; // ✅ Handle invalid dates
    
      const today = new Date();
      today.setHours(0, 0, 0, 0); // ✅ Normalize to avoid time-related errors
    
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
    
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
    
      return age >= 10 && age <= 100;
    };
  
    // Real-time validation for password
    const handlePasswordBlur = () => {
      if (!isValidPassword(formData.password)) {
        setPasswordError('Password must include at least one uppercase letter and be at least 8 characters long.');
      } else {
        setPasswordError('');
      }
    };
    

    // Real-time validation for confirm password
    const handleConfirmPasswordBlur = () => {
      if (formData.password !== formData.confirm_password) {
        setConfirmPasswordError('Passwords do not match.');
      } else {
        setConfirmPasswordError('');
      }
    };

    // Real-time validation for mobile
    const handleMobileBlur = () => {
      if (!isValidMobile(formData.mobile_no)) {
        setMobileError('Please enter a valid mobile number (10-15 digits).');
      } else {
        setMobileError('');
      }
    };
    const handleRegister = async (e) => {
      e.preventDefault();
      setLoading(true);
    
      // Ensure strand is always uppercase
      const registerData = {
        ...formData,
        strand: formData.strand ? formData.strand.toUpperCase() : '',
      };
    
      try {
        const response = await api.post('/register/', registerData, {
          headers: { 'Content-Type': 'application/json' },
        });
    
        console.log('API Response:', response); // Debugging log to verify response
    
        if (response.status === 201) {
          toast.success('Walk-in registration successful! Please check your email for further instructions.', {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            position: "top-right",
          });
    
          // Reset only relevant fields
          setFormData({
            full_name: '',
            email: '',
            strand: '',
            sex: '',
            walkIn: 'yes',  // Keep walk-in selection
          });
    
          setLoading(false);
          close(); // Close modal
          return; 
        }
    
        // If status is not 201, trigger error
        toast.error(response.data?.message || '❌ Registration failed. Please try again.', {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          position: "top-right",
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error('Error:', err);
    
        // Ensure only one error toast is shown
        const errorMessage = err.response?.data?.message || '❌ Registration failed. Please check your details.';
        toast.error(errorMessage, {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          position: "top-right",
        });
      }
    };
    
    
    
    return (
      <Modal show={isOpen} onHide={close} size="default" centered>
      <Modal.Header closeButton>
        <Modal.Title>Walk-in Registration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="register-form">
          <div className="row g-3">
            
            {/* Full Name */}
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="full_name"
                  placeholder="Full Name"
                  value={formData.full_name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="full_name">
                  <i className="fas fa-user me-2"></i> Full Name
                </label>
              </div>
            </div>
    
            {/* Email */}
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="email">
                  <i className="fas fa-envelope me-2"></i> Email
                </label>
              </div>
            </div>
    
            {/* Strand */}
            <div className="col-12">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="strand"
                  value={formData.strand}
                  onChange={handleChange}
                  required
                >
                  <option value="" hidden>Select Strand</option>
                  <option value="ABM">ABM</option>
                  <option value="ARTSDESIGN">ARTS & DESIGN</option>
                  <option value="STEM">STEM</option>
                  <option value="HUMSS">HUMSS</option>
                  <option value="TVL - Information and Communications Technology">TVL - Information and Communications Technology</option>
                  <option value="TVL - Home Economics">TVL - Home Economics</option>
                  <option value="TVL - Agri-Fishery ArtsL-AFA">TVL - Agri-Fishery Arts</option>
                  <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
                </select>
                <label htmlFor="strand">
                  <i className="fas fa-graduation-cap me-2"></i> Strand
                </label>
              </div>
            </div>
    
            {/* Sex */}
            <div className="col-12">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="sex"
                  value={formData.sex}
                  onChange={handleChange}
                  required
                >
                  <option value="" hidden>Select Sex</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <label htmlFor="sex">
                  <i className="fas fa-venus-mars me-2"></i> Sex
                </label>
              </div>
            </div>
    
          </div>
    
          {/* Submit Button */}
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-primary" type="button" onClick={handleRegister} disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Registering...
                </>
              ) : (
                'Register'
              )}
            </button>
          </div>
    
        </form>
      </Modal.Body>
    </Modal>
    
    );
  }    

  export default RegisterModal;
