import React, { useEffect, useContext } from 'react';
import { ChatbotContext } from '../../context/ChatbotContext';
import { useLanguage } from '../../context/LanguageContext';

const QuickReply = ({ reply, click, isRiasecQuickReplies }) => {
   const { setDisabledInput, setIsVisibleInput } = useContext(ChatbotContext);
   const { language } = useLanguage(); // Get current language

   useEffect(() => {
      if (!isRiasecQuickReplies) {
         setDisabledInput(true);
         setIsVisibleInput(false);
      }
   }, []);

   // Get the original text from Dialogflow
   let replyText = reply.structValue.fields.text.stringValue;
   let payloadValue = reply.structValue.fields.payload.stringValue;

   // Adjust "Yes/No" based on the selected language
   if (language === 'fil') {
      if (replyText.toLowerCase() === 'yes') {
         replyText = 'Oo';
      } else if (replyText.toLowerCase() === 'no') {
         replyText = 'Hindi';
      }
   } else if (language === 'en') {
      if (replyText.toLowerCase() === 'oo') {
         replyText = 'Yes';
      } else if (replyText.toLowerCase() === 'hindi') {
         replyText = 'No';
      }
   }

   return (
      <a
         href='/'
         className='btn btn-outline-primary message-quick-reply-btn m-1 rounded-pill px-3'
         onClick={e => click(e, payloadValue, replyText)}
      >
         {replyText}
      </a>
   );
};

export default QuickReply;
