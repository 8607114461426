import React, { useState, useEffect } from 'react';
import { Table, Form, Button, InputGroup, Row, Col, Pagination, Container, Modal, Alert, Spinner } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { Tabs, Tab, Card, Badge } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaPhoneAlt, FaGraduationCap, FaCalendarAlt, FaVenusMars, FaIdCard, FaLayerGroup } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import api from '../../utils/api';  // Import the API utility
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { motion } from 'framer-motion';
import apiNode from '../../components/chatbot/apiNode';
import MyPagination from './MyPagination.js'; // <--- Import your new pagination

const Conversations = () => {
  // Lowercase, remove non-alphanumeric characters
  const [conversations, setConversations] = useState([]); // Initialize as an empty array
  const [filters, setFilters] = useState({
    searchQuery: '',
    strand: 'Overall',
    schoolYear: 'Overall',
    section: 'Overall', // Add section filter
    gradeLevel: 'Overall',  // New filter for Grade Level


  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState(null);

  // Modal-related state
  const [showModal, setShowModal] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [userDetails, setUserDetails] = useState(null); // Store user details
  const [loading, setLoading] = useState(false); // Add this state
  const [sectionMap, setSectionMap] = useState({});
  const [pageLoading, setPageLoading] = useState(true); // For page load spinner
  const [sections, setSections] = useState([]); // For storing sections
  const [showAllRiasec, setShowAllRiasec] = useState(5); // Default to showing 5 items
  // Add these with your other useState declarations:
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [loadingCourse, setLoadingCourse] = useState(false);
  const [courseCache, setCourseCache] = useState({});
  const [showAllStrand, setShowAllStrand] = useState(5); // Default to showing 5 items

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await api.get('/api/sections'); // Adjust endpoint if needed
        if (response.status === 200) {
          setSections(response.data); // Save sections directly
        }
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    };
  
    fetchSections();
  }, []);
  
  const cleanAndParse = (data) => {
    if (!data) return null;
    if (typeof data === 'object') return data; // If it's already an object (or array), return it.
    try {
      return JSON.parse(data);
    } catch (e) {
      console.error("Invalid JSON in recommendation field:", data);
      return null;
    }
  };
  
  
  
  const refreshAccessToken = async (navigate) => {
    try {
      const response = await api.post('/token/refresh/', {
        refresh: localStorage.getItem('refreshToken'),
      });
  
      if (response.status === 200) {
        localStorage.setItem('token', response.data.access);
        return response.data.access;
      } else {
        console.error('Refresh token invalid or expired:', response.data);
        navigate('/admin/login');
        return null;
      }
    } catch (err) {
      console.error('Error refreshing access token:', err);
      navigate('/admin/login');
      return null;
    }
  };
  
  
  const [courses, setCourses] = useState([]); // New state for courses
  const getFilteredConversations = () => {
    return conversations.filter((conversation) => {
      // Filter by strand (case-insensitive)
      const normalizedStrand = (conversation.strand || "").trim().toLowerCase();
      const filterStrand = (filters.strand || "").trim().toLowerCase();
      if (filters.strand !== "Overall" && normalizedStrand !== filterStrand) {
        return false;
      }
  
      // Filter by section
      if (filters.section !== "Overall" && conversation.section !== filters.section) {
        return false;
      }
  
      // NEW: Filter by grade level (ensure both sides are strings)
      if (
        filters.gradeLevel !== "Overall" &&
        String(conversation.grade_level) !== filters.gradeLevel
      ) {
        return false;
      }
  
      // Validate the conversation's creation date
      const conversationDate = new Date(conversation.created_at);
      if (isNaN(conversationDate.getTime())) {
        return false;
      }
  
      // Handle school year filtering
      if (filters.schoolYear !== "Overall") {
        const schoolYearRange = filters.schoolYear.match(/\d{4}/g); // Extract year numbers
        if (!schoolYearRange || schoolYearRange.length !== 2) {
          return false; // Invalid school year format
        }
        const [startYear, endYear] = schoolYearRange.map(Number);
        // Academic year: starts in June and ends in May
        const schoolYearStartDate = new Date(startYear, 5, 1); // June 1st of start year
        const schoolYearEndDate = new Date(endYear, 4, 31, 23, 59, 59); // May 31st of end year
  
        // Ensure conversation date falls within the academic year range
        if (conversationDate < schoolYearStartDate || conversationDate > schoolYearEndDate) {
          return false;
        }
      }
  
      // Passed all filters
      return true;
    });
  };
  
  
  

  
    const filteredConversations = getFilteredConversations();
    const totalPages = Math.ceil(filteredConversations.length / itemsPerPage);

  const fetchConversationsAndCourses = async () => {
    setLoading(true);
    try {
      let accessToken = localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        search: filters.searchQuery || '',
        strand: filters.strand !== 'Overall' ? filters.strand : '',
        school_year: filters.schoolYear !== 'Overall' ? filters.schoolYear : '',
        section: filters.section !== 'Overall' ? filters.section : '',
      });
  
      // Fetch conversations
      const response = await api.get(`auth/get-conversations/?${queryParams.toString()}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        const data = response.data.conversations;
        console.log('Fetched Data:', data);
  
        // ✅ Ensure each user only has their latest conversation
        const latestConversationsMap = new Map();
  
        data.forEach((conv) => {
          if (!latestConversationsMap.has(conv.user_id) || 
              new Date(conv.created_at) > new Date(latestConversationsMap.get(conv.user_id).created_at)) {
            latestConversationsMap.set(conv.user_id, conv);
          }
        });
  
        // Convert Map values back to an array
        const latestConversations = Array.from(latestConversationsMap.values());
  
        // Normalize and filter conversations
        const normalizedConversations = latestConversations.map((conversation) => ({
          ...conversation,
          riasec_course_recommendation: cleanAndParse(conversation.riasec_course_recommendation),
          strand_course_recommendation: cleanAndParse(conversation.strand_course_recommendation),
          isVisible: filters.strand === 'Overall' || conversation.strand === filters.strand,
        }));
        
  
        // Set state with only the latest conversations per user
        setConversations(normalizedConversations.filter((conv) => conv.isVisible));
      } else {
        console.error('Failed to fetch conversations:', response.status, response.statusText);
      }
  
      // Fetch courses with RIASEC areas
      const courseResponse = await api.get('https://node-backend-604521917673.asia-northeast1.run.app/api/courses');
      setCourses(courseResponse.data); // Save the fetched courses
    } catch (error) {
      console.error('Failed to fetch conversations or courses:', error);
      setError('Failed to load conversations.');
    } finally {
      setLoading(false); // Stop filter/search loading spinner
    }
  };
  
  // ✅ Call fetch function inside useEffect
  useEffect(() => {
    fetchConversationsAndCourses();
  }, [filters]);
  
  
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setPageLoading(true); // Indicate that the page is loading
        setError(null); // ✅ Clear error before fetching data
  
        let sectionsFetched = false;
        let conversationsFetched = false;
  
        // Fetch sections
        try {
          const sectionsResponse = await api.get('/api/sections');
          if (sectionsResponse.status === 200) {
            const sectionMap = sectionsResponse.data.reduce((acc, section) => {
              acc[section.id] = section.name;
              return acc;
            }, {});
            setSectionMap(sectionMap);
            sectionsFetched = true; // ✅ Mark sections as fetched
          }
        } catch (sectionError) {
          console.error('Error fetching sections:', sectionError);
        }
  
        // Fetch conversations and courses
        try {
          await fetchConversationsAndCourses();
          conversationsFetched = true; // ✅ Mark conversations as fetched
        } catch (convoError) {
          console.error('Error fetching conversations:', convoError);
        }
  
        // ✅ Only set error if both API calls fail
        if (!sectionsFetched && !conversationsFetched) {
          setError('Failed to load initial data.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setError('Failed to load initial data.');
      } finally {
        setPageLoading(false); // Stop page loading spinner
      }
    };
  
    fetchInitialData(); // ✅ Runs only on component mount
  }, []); // ✅ Empty dependency array ensures it runs once
  
  
  
  
  

  // Function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatRiasecCode = (riasecCode) => {
    if (!riasecCode || !Array.isArray(riasecCode)) return 'N/A';

    // Extract the first letter of each trait in the RIASEC code
    const code = riasecCode
      .map(trait => trait[0][0].toUpperCase())  // Get the first letter of the trait name
      .join('');  // Join the letters

    return <strong>{code}</strong>;  // Return the code in bold
  };
  const fetchCourseDetails = async (courseName) => {
    setShowCourseModal(true);       // Open the course modal
    setLoadingCourse(true);         // Start the loading spinner
  
    // Check if the course details are already cached
    if (courseCache[courseName]) {
      setSelectedCourse(courseCache[courseName]);
      setLoadingCourse(false);
      return;
    }
  
    try {
      const formattedCourseName = encodeURIComponent(courseName.trim());
      console.log(`Fetching course: ${formattedCourseName}`);
  
      // Fetch course details from your Node API
      const response = await apiNode.get(`/api/courses?name=${formattedCourseName}`);
  
      if (response.data) {
        setCourseCache(prevCache => ({ ...prevCache, [courseName]: response.data }));
        setSelectedCourse(response.data);
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
      toast.error("Failed to load course details");
    } finally {
      setLoadingCourse(false);
    }
  };
  
  
  // Function to handle filter changes
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setLoading(true); // Start loading when filters change
  };
  

  const fetchUserDetails = async (userId) => {
    try {
      let accessToken = localStorage.getItem('token');
      const response = await api.get(`/get-user/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setUserDetails(response.data);
      } else {
        console.error('Failed to fetch user details:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  const handleSingleUserExport = async (data) => {
    toast.info("Exporting single user data...");
  
    try {
      // Ensure sections are fetched before export
      if (!Object.keys(sectionMap).length) {
        const response = await api.get('/api/sections'); // Fetch sections if not already fetched
        if (response.status === 200) {
          const map = response.data.reduce((acc, section) => {
            acc[section.id] = section.name;
            return acc;
          }, {});
          setSectionMap(map);
        }
      }
  
      // Prepare data with mapped sections
      const formattedData = data.map((row) => ({
        Name: row.Name || userDetails?.full_name || "N/A",
        "ID No": row["ID No"] || userDetails?.id_no || "N/A",
        Section: sectionMap[userDetails?.section_id] || "N/A", // Map section_id to section name
        "Grade Level": row["Grade Level"] || userDetails?.grade_level || "N/A",
        Age: row.Age || selectedConversation?.age || "N/A",
        Sex: row.Sex || userDetails?.sex || "N/A",
        Strand: 
        row.Strand || 
        selectedConversation?.strand || 
        userDetails?.strand || 
        "N/A",        "Test Taken Date": row["Test Taken Date"] || formatDate(selectedConversation?.created_at) || "N/A",
        "Realistic Score": row["Realistic Score"] || selectedConversation?.realistic_score || "N/A",
        "Investigative Score": row["Investigative Score"] || selectedConversation?.investigative_score || "N/A",
        "Artistic Score": row["Artistic Score"] || selectedConversation?.artistic_score || "N/A",
        "Social Score": row["Social Score"] || selectedConversation?.social_score || "N/A",
        "Enterprising Score": row["Enterprising Score"] || selectedConversation?.enterprising_score || "N/A",
        "Conventional Score": row["Conventional Score"] || selectedConversation?.conventional_score || "N/A",
        "RIASEC Course Recommendations": selectedConversation?.riasec_course_recommendation
  ? Object.values(selectedConversation.riasec_course_recommendation)
      .flat()
      .map(course => course.name)
      .join(", ")
  : 'N/A',


          // ★ For Strand, it's an array of strings:
      "Strand Course Recommendations": selectedConversation?.strand_course_recommendation
      ? selectedConversation.strand_course_recommendation.join(", ")
      : "N/A",
  }));

  
      // Convert to worksheet
      const ws = XLSX.utils.json_to_sheet(formattedData);
  
     // Adjust column widths
     ws["!cols"] = [
      { wch: 20 }, // Name
      { wch: 20 }, // ID No (increased width)
      { wch: 20 }, // Section
      { wch: 5 }, // Grade Level
      { wch: 5 }, // Age
      { wch: 10 }, // Sex
      { wch: 10 }, // Strand
      { wch: 15 }, // Test Taken Date (increased width)
      { wch: 5 }, // Realistic Score
      { wch: 5 }, // Investigative Score
      { wch: 5 }, // Artistic Score
      { wch: 5 }, // Social Score
      { wch: 5 }, // Enterprising Score
      { wch: 5 }, // Conventional Score
      { wch: 5 }, // RIASEC Course Recommendations
      { wch: 30 }, // Strand Course Recommendations
    ];

      // Create workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Single User Data");
  
      // Export as .xlsx file
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, `${userDetails?.full_name || "user"}_details.xlsx`);
  
      toast.success("Single user export successful!");
    } catch (error) {
      toast.error("Export failed. Please try again.");
      console.error("Export Error:", error);
    }
  };
  const handleExportClick = () => {
    toast.info("Exporting...");
  
    try {
      // Define headers (for context, though not directly used here)
      const headers = [
        "Name",
        "ID No",
        "Section",
        "Grade Level",
        "Age",
        "Sex",
        "Strand",
        "Test Taken Date",
        "Realistic Score",
        "Investigative Score",
        "Artistic Score",
        "Social Score",
        "Enterprising Score",
        "Conventional Score",
        "RIASEC Course Recommendations",
        "Strand Course Recommendations",
      ];
  
      // Prepare data from the filtered conversations
      const formattedData = filteredConversations.map((row) => {
        // Parse the RIASEC course recommendations
        const riasec = cleanAndParse(row.riasec_course_recommendation);
        let riasecRecommendations = "N/A";
        if (riasec && typeof riasec === "object") {
          riasecRecommendations = Object.values(riasec)
            .flat()
            .map((course) => course.name)
            .join(", ");
        }
  
        // Parse the Strand course recommendations
        const strand = cleanAndParse(row.strand_course_recommendation);
        let strandRecommendations = "N/A";
        if (Array.isArray(strand)) {
          strandRecommendations = strand.join(", ");
        } else if (typeof row.strand_course_recommendation === "string") {
          strandRecommendations = row.strand_course_recommendation;
        }
  
        return {
          Name: row.name || "N/A",
          "ID No": row.id_no || "N/A",
          Section: row.section || "N/A",
          "Grade Level": row.grade_level || "N/A",
          Age: row.age || "N/A",
          Sex: row.sex || "N/A",
          Strand: row.strand || "N/A",
          "Test Taken Date": formatDate(row.created_at) || "N/A",
          "Realistic Score": row.realistic_score || "N/A",
          "Investigative Score": row.investigative_score || "N/A",
          "Artistic Score": row.artistic_score || "N/A",
          "Social Score": row.social_score || "N/A",
          "Enterprising Score": row.enterprising_score || "N/A",
          "Conventional Score": row.conventional_score || "N/A",
          "RIASEC Course Recommendations": riasecRecommendations,
          "Strand Course Recommendations": strandRecommendations,
        };
      });
  
      // Convert the formatted data to a worksheet
      const ws = XLSX.utils.json_to_sheet(formattedData);
  
      // Adjust column widths
      ws["!cols"] = [
        { wch: 20 }, // Name
        { wch: 25 }, // ID No (increased width)
        { wch: 20 }, // Section
        { wch: 5 },  // Grade Level
        { wch: 5 },  // Age
        { wch: 10 }, // Sex
        { wch: 10 }, // Strand
        { wch: 15 }, // Test Taken Date (increased width)
        { wch: 5 },  // Realistic Score
        { wch: 5 },  // Investigative Score
        { wch: 5 },  // Artistic Score
        { wch: 5 },  // Social Score
        { wch: 5 },  // Enterprising Score
        { wch: 5 },  // Conventional Score
        { wch: 30 }, // RIASEC Course Recommendations
        { wch: 30 }, // Strand Course Recommendations
      ];
  
      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Conversations");
  
      // Write the workbook to an Excel file and trigger the download
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "conversations_data.xlsx");
  
      toast.success("Export successful!");
    } catch (error) {
      toast.error("Export failed. Please try again.");
      console.error("Export Error:", error);
    }
  };
  
  
  // Handle modal open and fetching details
  const handleShowDetails = (conversation) => {
    if (!conversation.user_id) {
      console.error("No user_id found in conversation");
      return;
    }
    
    setSelectedConversation(conversation); // Set the selected conversation details
    fetchUserDetails(conversation.user_id); // Fetch user details using user_id
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedConversation(null);
    setUserDetails(null); // Reset user details
  };

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentConversations = conversations.slice(indexOfFirstItem, indexOfLastItem); // Display conversations for the current page

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const abbreviateStrand = (strand) => {
    const formattedStrand = (strand || "").trim().toLowerCase();
    switch (formattedStrand) {
      case 'tvl - information and communications technology':
        return 'TVL - ICT';
      case 'tvl - home economics':
        return 'TVL - HE';
      case 'tvl - agri-fishery arts':
        return 'TVL - AFA';
      case 'tvl - industrial arts':
        return 'TVL - IA';
      default:
        return strand; // Return the original strand if no abbreviation is needed
    }
  };
  
  
  const csvData = [
    {
      Name: userDetails?.full_name || 'N/A',
      "ID No": userDetails?.id_no || 'N/A',
      Section: userDetails?.section || 'N/A',
      "Grade Level": userDetails?.grade_level || 'N/A',
      Age: selectedConversation?.age || 'N/A',
      Sex: userDetails?.sex || 'N/A',
      Strand: userDetails?.strand || 'N/A',
      "Test Taken Date": formatDate(selectedConversation?.created_at) || 'N/A',
      "Realistic Score": selectedConversation?.realistic_score || 'N/A',
      "Investigative Score": selectedConversation?.investigative_score || 'N/A',
      "Artistic Score": selectedConversation?.artistic_score || 'N/A',
      "Social Score": selectedConversation?.social_score || 'N/A',
      "Enterprising Score": selectedConversation?.enterprising_score || 'N/A',
      "Conventional Score": selectedConversation?.conventional_score || 'N/A',
      "RIASEC Course Recommendations": selectedConversation?.riasec_course_recommendation
        ? Object.values(selectedConversation.riasec_course_recommendation)
            .flat()
            .map(course => course.name)
            .join(", ")
        : 'N/A',
      "Strand Course Recommendations": selectedConversation?.strand_course_recommendation
        ? Object.values(selectedConversation.strand_course_recommendation)
            .flat()
            .map(course => course.name)
            .join(", ")
        : 'N/A',
    },
  ];
  
  
  
  
  const csvAllData = conversations.map((conversation) => ({
    Name: conversation.name || 'N/A',
    "ID No": conversation.id_no || 'N/A',
    Section: conversation.section || 'N/A',
    "Grade Level": conversation.grade_level || 'N/A',
    Age: conversation.age || 'N/A',
    Sex: conversation.sex || 'N/A',
    Strand: conversation.strand || 'N/A',
    "Test Taken Date": formatDate(conversation.created_at) || 'N/A', // Add this line
    "Realistic Score": conversation.realistic_score || 'N/A',
    "Investigative Score": conversation.investigative_score || 'N/A',
    "Artistic Score": conversation.artistic_score || 'N/A',
    "Social Score": conversation.social_score || 'N/A',
    "Enterprising Score": conversation.enterprising_score || 'N/A',
    "Conventional Score": conversation.conventional_score || 'N/A',
"RIASEC Course Recommendations": Array.isArray(conversation.strand_course_recommendation)
  ? conversation.strand_course_recommendation.join(", ")
  : "N/A",

"Strand Course Recommendations": conversation.riasec_course_recommendation
  ? Object.values(conversation.riasec_course_recommendation)
      .flat()
      .map((course) => course.name)
      .join(", ")
  : "N/A",

  }));
  


  if (pageLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading conversations...</p>
      </div>
    );
  }
  
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        {/* Main Content */}
        <Col xs={12} className="p-4">
          <h2 className="fw-bold text-center mb-4">Conversations</h2>
  
          {error && <div className="alert alert-danger">{error}</div>}
  
          {/* Search and Filters */}
          {/* Labels Row for Search, Strand, Sections, School Year */}
{/* Labels Row (Strand, Sections, School Year) */}
{/* First Row: Only the three labels aligned (Strand, Sections, School Year) */}
{/* Single Row: Search, Strand, Sections, School Year, Export */}
<Row className="mb-4 d-flex align-items-end justify-content-between">
  {/* Column 1: Search */}
  <Col xs={12} md={3}>
    <Form.Group controlId="searchGroup">
      <Form.Label className="invisible">Search</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Search"
          name="searchQuery"
          value={filters.searchQuery}
          onChange={handleFilterChange}
          style={{ fontSize: "0.95rem", padding: "0.5rem" }}
        />
        <Button variant="warning" style={{ padding: "0.5rem" }}>
          <i className="fa fa-search"></i>
        </Button>
      </InputGroup>
    </Form.Group>
  </Col>

  {/* Column 2: Strand */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>Strand</Form.Label>
      <Form.Select
        name="strand"
        value={filters.strand}
        onChange={handleFilterChange}
        style={{ fontSize: "0.8rem", padding: "0.3rem", maxWidth: '150px' }}
      >
        <option value="Overall">Overall</option>
        <option value="STEM">STEM</option>
        <option value="ABM">ABM</option>
        <option value="ARTS & DESIGN">ARTS & DESIGN</option>
        <option value="HUMSS">HUMSS</option>
        <option value="TVL - INFORMATION AND COMMUNICATIONS TECHNOLOGY">
          TVL - INFORMATION AND COMMUNICATIONS TECHNOLOGY
        </option>
        <option value="TVL - HOME ECONOMICS">TVL - HOME ECONOMICS</option>
        <option value="TVL - AGRI-FISHERY ARTS">TVL - AGRI-FISHERY ARTS</option>
        <option value="TVL - INDUSTRIAL ARTS">TVL - INDUSTRIAL ARTS</option>
      </Form.Select>
    </Form.Group>
  </Col>

  {/* Column 3: Sections */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>Sections</Form.Label>
      <Form.Select
        name="section"
        value={filters.section}
        onChange={(e) => setFilters({ ...filters, section: e.target.value })}
        style={{ fontSize: "0.8rem", padding: "0.3rem", maxWidth: '150px' }}
      >
        <option value="Overall">Overall</option>
        {sections.map((section) => (
          <option key={section.id} value={section.name}>
            {section.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  </Col>

  {/* Column 4: Grade Level */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>Grade Level</Form.Label>
      <Form.Select
        name="gradeLevel"
        value={filters.gradeLevel}
        onChange={handleFilterChange}
        style={{ fontSize: "0.8rem", padding: "0.3rem", maxWidth: '150px' }}
      >
        <option value="Overall">Overall</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </Form.Select>
    </Form.Group>
  </Col>

  {/* Column 5: School Year */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>School Year</Form.Label>
      <Form.Select
        name="schoolYear"
        value={filters.schoolYear}
        onChange={handleFilterChange}
        style={{ fontSize: "0.8rem", padding: "0.3rem", maxWidth: '150px' }}
      >
        <option value="Overall">Overall</option>
        <option value="2022-2023">2022-2023</option>
        <option value="2023-2024">2023-2024</option>
        <option value="2024-2025">2024-2025</option>
      </Form.Select>
    </Form.Group>
  </Col>

  {/* Column 6: Export Button */}
  <Col xs={12} md={1} className="text-end">
    <Form.Group>
      <Form.Label className="invisible">Export</Form.Label>
      <Button
        variant="primary"
        onClick={handleExportClick}
        className="w-100"
        style={{ fontSize: "0.9rem", padding: "0.4rem 0.8rem" }}
      >
        <i className="fa fa-download"></i> Export All
      </Button>
    </Form.Group>
  </Col>
</Row>
          {/* Conversations Table */}
          <Table striped bordered hover responsive>
  <thead style={{ backgroundColor: 'var(--primary)', color: '#fff' }}>
    <tr>
      <th className="text-center">Date Taken</th>
      <th className="text-center">ID No</th>
      <th className="text-center">Student Name</th>
      <th className="text-center">Section</th>
      <th className="text-center">Grade Level</th>
      <th className="text-center">Sex</th>
      <th className="text-center">Strand</th>
      <th className="text-center">RIASEC Code</th>
      <th className="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
  {loading ? (
    <tr>
      <td colSpan="9" className="text-center">
        <Spinner animation="border" size="sm" /> Loading conversations...
      </td>
    </tr>
  ) : filteredConversations.length > 0 ? (
    filteredConversations.slice(indexOfFirstItem, indexOfLastItem).map((conversation, index) => (
      <tr key={conversation.id || index}>
        <td className="text-center">{formatDate(conversation.created_at)}</td>
        <td className="text-center">{conversation.id_no || "N/A"}</td>
        <td className="text-center">{conversation.name || "N/A"}</td>
        <td className="text-center">{conversation.section || "N/A"}</td>
        <td className="text-center">{conversation.grade_level || "N/A"}</td>
        <td className="text-center">{conversation.sex || "N/A"}</td>
        <td className="text-center">{abbreviateStrand(conversation.strand) || "N/A"}</td>
        <td className="text-center">{formatRiasecCode(conversation.riasec_code)}</td>
        <td className="text-center">
          <Button
            variant="link"
            className="text-warning"
            onClick={() => handleShowDetails(conversation)}
            title="View Details"
          >
            <FaEye />
          </Button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9" className="text-center">
        No conversations available. Adjust filters or search to find results.
      </td>
    </tr>
  )}
</tbody> 

</Table>

  
          {/* Pagination */}
          {conversations.length > 0 && (
       <Row className="mt-3 align-items-center">
       {/* Left: Items per page */}
       <Col xs="auto">
         <div>
           Items per page:
           <Form.Select
             style={{ width: '80px', display: 'inline-block', marginLeft: '0.5rem' }}
             value={itemsPerPage}
             onChange={(e) => setItemsPerPage(Number(e.target.value))}
           >
             <option value={5}>5</option>
             <option value={10}>10</option>
             <option value={20}>20</option>
           </Form.Select>
         </div>
       </Col>
     
       {/* Grow the middle space so the pagination ends up centered overall */}
       <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
         <MyPagination
           currentPage={currentPage}
           totalItems={filteredConversations.length}
           itemsPerPage={itemsPerPage}
           onPageChange={(page) => setCurrentPage(page)}
         />
       </Col>
     </Row>
     
)}

        </Col>
        </Row>
        <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
  <Modal.Header closeButton className="bg-primary text-white py-3">
    <Modal.Title className="w-100 text-center fw-bold">
      {userDetails?.full_name ? `${userDetails.full_name}'s Details` : 'Details'}
    </Modal.Title>
  </Modal.Header>

  <Modal.Body className="bg-light">
    {selectedConversation && userDetails ? (
      <Tabs
        defaultActiveKey="userInfo"
        id="details-tabs"
        variant="pills"
        fill
        className="mb-3 p-2"
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
      >
    {/* 🔹 User Information Tab */}
<Tab eventKey="userInfo" title="User Information">
  <div className="p-4">
    <Card className="shadow border-0">
      <Card.Body>
        <Row>
          <Col md={6} className="pe-md-5">
            {[
              {
                icon: <FaUser />,
                label: 'Name',
                value: userDetails.full_name
              },
              {
                icon: <FaEnvelope />,
                label: 'Email',
                value: userDetails.email
              },
              {
                icon: <FaGraduationCap />,
                label: 'Grade Level',
                value: userDetails.grade_level
              },
              {
                icon: <FaLayerGroup />,
                label: 'Strand',
                value: userDetails.strand
              },
              {
                icon: <FaCalendarAlt />,
                label: 'Test Taken',
                value: formatDate(selectedConversation.created_at)
              }
            ].map((item, idx) => (
              <div key={idx} className="d-flex align-items-start mb-4">
                <div className="text-warning fs-5 me-3">{item.icon}</div>
                <div>
                  <small className="text-muted">{item.label}</small>
                  <div className="fw-bold">{item.value}</div>
                </div>
              </div>
            ))}
          </Col>

          <Col md={6} className="ps-md-5">
            {[
              {
                icon: <FaIdCard />,
                label: 'ID No',
                value: userDetails.id_no
              },
              {
                icon: <FaPhoneAlt />,
                label: 'Mobile No',
                value: userDetails.mobile_no
              },
              {
                icon: <FaVenusMars />,
                label: 'Sex',
                value: userDetails.sex
              },
              {
                icon: <FaCalendarAlt />,
                label: 'Age',
                value: selectedConversation.age || 'N/A'
              }
            ].map((item, idx) => (
              <div key={idx} className="d-flex align-items-start mb-4">
                <div className="text-warning fs-5 me-3">{item.icon}</div>
                <div>
                  <small className="text-muted">{item.label}</small>
                  <div className="fw-bold">{item.value}</div>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </div>
</Tab>

        {/* 🎯 RIASEC Scores Tab */}
        <Tab eventKey="riasecScores" title="RIASEC Scores">
          <div className="p-3">
            <Card className="shadow border-0">
              <Card.Body>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h5 className="fw-bold">RIASEC Scores</h5>
                    <Badge bg="success" className="fs-6">
                      {formatRiasecCode(selectedConversation.riasec_code)}
                    </Badge>
                  </Col>
                </Row>
                <Row className="gy-3">
                  {[
                    { label: 'Realistic', score: selectedConversation.realistic_score },
                    { label: 'Investigative', score: selectedConversation.investigative_score },
                    { label: 'Artistic', score: selectedConversation.artistic_score },
                    { label: 'Social', score: selectedConversation.social_score },
                    { label: 'Enterprising', score: selectedConversation.enterprising_score },
                    { label: 'Conventional', score: selectedConversation.conventional_score },
                  ].map((item, idx) => (
                    <Col md={4} sm={6} xs={12} key={idx}>
                      <Card className="text-center bg-white shadow-sm border-0">
                        <Card.Body>
                          <div className="fs-3 fw-bold text-primary">{item.score}</div>
                          <small className="text-muted">{item.label}</small>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Tab>
{/* RIASEC Course Recommendations Tab */}
<Tab eventKey="courseRecommendations" title="Course Recommendations">
  <div className="p-3">
    <p className="text-center mb-4">
      <strong>RIASEC Course Recommendations:</strong>
    </p>

    <Row className="justify-content-center">
      {selectedConversation.riasec_course_recommendation ? (
        Object.entries(selectedConversation.riasec_course_recommendation).map(([category, courses]) => (
          <div key={category} className="mb-3 w-100">
            <h6 className="text-center">{category.toUpperCase()}</h6>
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="list-group list-group-flush border rounded"
            >
              {courses.slice(0, showAllRiasec).map((course, index) => (
                <motion.li 
                  key={index}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="list-group-item py-2 course-item"
                  onClick={() => fetchCourseDetails(course.name)}
                >
                  {course.name}
                </motion.li>
              ))}
            </motion.ul>
          </div>
        ))
      ) : (
        <p className="text-center">No recommendations available</p>
      )}
    </Row>

    {/* Show "See More" Button Only If There Are More Items to Display */}
    {selectedConversation.riasec_course_recommendation &&
      showAllRiasec < Object.values(selectedConversation.riasec_course_recommendation).flat().length && (
        <div className="text-center mt-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="btn btn-sm btn-outline-primary"
            onClick={() => setShowAllRiasec((prev) => prev + 10)}
            >
            See More
          </motion.button>
        </div>
      )}

    {/* "See Less" Button - Only Show When All Items Are Displayed */}
    {selectedConversation.riasec_course_recommendation &&
      showAllRiasec >= Object.values(selectedConversation.riasec_course_recommendation).flat().length && showAllRiasec > 5 && (
        <div className="text-center mt-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="btn btn-sm btn-outline-secondary"
            onClick={() => setShowAllRiasec(5)}
          >
            See Less
          </motion.button>
        </div>
      )}
  </div>
</Tab>


{/* Strand Recommendations Tab */}
<Tab eventKey="strandRecommendations" title="Strand Recommendations">
  <div className="p-3">
    <p className="text-center mb-4">
      <strong>Strand Course Recommendations:</strong>
    </p>

    <Row className="justify-content-center">
      {selectedConversation.strand_course_recommendation &&
      selectedConversation.strand_course_recommendation.length > 0 ? (
        <div className="mb-3 w-100">
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="list-group list-group-flush border rounded"
          >
            {selectedConversation.strand_course_recommendation.slice(0, showAllStrand).map((course, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="list-group-item py-2 course-item"
                style={{ cursor: "pointer" }}
                onClick={() => fetchCourseDetails(course)}
              >
                {course}
              </motion.li>
            ))}
          </motion.ul>
        </div>
      ) : (
        <p className="text-center">No recommendations available</p>
      )}
    </Row>

    {/* Show "See More" Button Only If There Are More Items to Display */}
    {selectedConversation.strand_course_recommendation &&
      showAllStrand < selectedConversation.strand_course_recommendation.length && (
        <div className="text-center mt-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="btn btn-sm btn-outline-primary"
            onClick={() => setShowAllStrand((prev) => prev + 5)}
          >
            See More
          </motion.button>
        </div>
      )}

    {/* Show "See Less" Button Only After Displaying All Items */}
    {selectedConversation.strand_course_recommendation &&
      showAllStrand >= selectedConversation.strand_course_recommendation.length && showAllStrand > 5 && (
        <div className="text-center mt-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="btn btn-sm btn-outline-secondary"
            onClick={() => setShowAllStrand(5)}
          >
            See Less
          </motion.button>
        </div>
      )}
  </div>
</Tab>



      </Tabs>
    ) : (
      <p className="text-center">Loading details...</p>
    )}
  </Modal.Body>

  <Modal.Footer className="d-flex justify-content-end" style={{ backgroundColor: '#f8f9fa' }}>
  <Button
    className="btn btn-success"
    style={{ backgroundColor: '#198754', color: '#fff' }}
    onClick={() => handleSingleUserExport(csvData)}
  >
    Download XLSX
  </Button>
</Modal.Footer>
</Modal>
{showCourseModal && (
  <Modal show={showCourseModal} onHide={() => setShowCourseModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>
        {loadingCourse ? "Loading course details..." : selectedCourse?.name}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {loadingCourse ? (
        <Spinner animation="border" role="status" className="d-block mx-auto" />
      ) : (
        <>
          <p><strong>Description:</strong> {selectedCourse?.description}</p>
          <p>
            <strong>RIASEC Areas:</strong>{" "}
            {Array.isArray(selectedCourse?.riasec_area)
              ? selectedCourse.riasec_area.join(', ')
              : selectedCourse?.riasec_area}
          </p>
          <p><strong>Category:</strong> {selectedCourse?.category || 'N/A'}</p>
        </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowCourseModal(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
)}


    </Container>
  );
};

export default Conversations;
