import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from 'react-modal-state';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { UserContext } from '../context/UserContext';
import { ChatbotContext } from '../context/ChatbotContext';
import anna from '../assets/Anna_1.svg';
import api from '../utils/api';
import '../styles/Header.css'; // Import the new CSS file

const Header = ({ navlinks, handleLogout }) => {
  const { isAuthenticated, user } = useContext(UserContext);
  const { open: openLoginModal } = useModal('login');
  const { setShowbot } = useContext(ChatbotContext);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const userName = user?.full_name || 'User';

  
  // New useEffect for handling localStorage changes
  useEffect(() => {
    const handleStorageChange = (event) => {
      // Check if the changed item is the "authToken"
      if (event.key === 'authToken') {
        // If the token was removed or is now null, call `handleLogout()`
        if (!localStorage.getItem('authToken') && isAuthenticated) {
          handleLogout();
        }
      }
    };

    // Listen for localStorage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isAuthenticated, handleLogout]);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
    setShowbot(false);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
    setShowbot(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div className="container">
        <a className="navbar-brand d-flex align-items-center" href="/#home">
          <img
            className="anna-logo"
            src={anna}
            alt="anna-logo"
            style={{ width: '40px', height: '40px' }}
          />
<h1 className="h3 ms-2 custom-heading custom-warning mb-0">Anna</h1>


        </a>
        <button
          className={`navbar-toggler ${isNavbarOpen ? '' : 'collapsed'}`}
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavbarOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto align-items-center justify-content-center text-center">
            {navlinks.map((link, i) => (
              <li key={i} className="nav-item">
                <a
                  href={link.link}
                  className="nav-link"
                  onClick={closeNavbar}
                >
                  {link.text}
                </a>
              </li>
            ))}
            {!isAuthenticated ? (
              <li className="nav-item">
                <button
                  className="btn btn-primary"
                  style={{
                    padding: '0.5rem 1rem',
                    fontSize: '0.9rem',
                    textTransform: 'uppercase',
                  }}
                  onClick={() => {
                    openLoginModal();
                    closeNavbar();
                  }}
                >
                  Login
                </button>
              </li>
            ) : (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {userName || 'User'}
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end text-center"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/account"
                      onClick={closeNavbar}
                    >
                      Account
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/result"
                      onClick={closeNavbar}
                    >
                      Results
                    </Link>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        handleLogout();
                        closeNavbar();
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
