import React, { useContext, useState } from 'react';
import { useModalInstance, useModal } from 'react-modal-state';
import { Modal } from 'react-bootstrap';
import anna from '../../assets/Anna_1.svg';
import api from '../../utils/api';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/LoginModal.css'; // Ensure you have a CSS file for any custom styles

const LoginModal = () => {
  const { isOpen, close } = useModalInstance();
  const { setIsAuthenticated } = useContext(UserContext);
  const { open: openRegisterModal } = useModal('register');
  const { open: openForgotPasswordModal } = useModal('forgotPassword');

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);
  
    const loginData = {
      email: e.target.loginEmail.value,
      password: e.target.loginPassword.value,
    };
  
    try {
      const response = await api.post('/auth/token/', loginData, {
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (response.status === 200) {
        // Immediately check login status (includes is_staff/is_superuser)
        const statusResponse = await api.get('/api/check_login_status/', {
          headers: { Authorization: `Bearer ${response.data.access}` },
        });
        const userData = statusResponse.data.user_data;
  
        if (userData.is_staff || userData.is_superuser) {
          toast.error('Only student accounts are allowed to login.');
          return setIsLoggingIn(false);
        }
  
        // If not staff/superuser, finalize login
        setIsAuthenticated(true);
        localStorage.setItem('authToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        localStorage.setItem('isAuthenticated', 'true');
  
        toast.success('Login successful!');
        close();
        window.dispatchEvent(new Event('loginSuccess'));
      }
    } catch (err) {
      console.error('Login error:', err);
      toast.error(err.response?.data?.detail || 'Login failed. Please check your credentials.');
    } finally {
      setIsLoggingIn(false);
    }
  };
  
  const handleSignUp = () => {
    close();
    setTimeout(() => {
      openRegisterModal();
    }, 300);
  };

  const handleForgotPassword = () => {
    close();
    setTimeout(() => {
      openForgotPasswordModal();
    }, 300);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Modal show={isOpen} onHide={close} size="lg" dialogClassName="login-modal">
      <Modal.Header closeButton>
        <Modal.Title>Sign In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleLogin} className="login-form">
          <div className="d-flex flex-column align-items-center">
            <img src={anna} alt="Anna" className="mb-3" style={{ width: '150px', height: '150px' }} />
            <h2 className="mb-3 modal-title">Sign In</h2>
          </div>
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <i className="fas fa-envelope"></i>
            </span>
            <input
              type="email"
              className="form-control"
              id="loginEmail"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-3 input-group">
  <span className="input-group-text">
    <i className="fas fa-lock"></i>
  </span>
  <input
    type={showPassword ? 'text' : 'password'}
    className="form-control"
    id="loginPassword"
    placeholder="Password"
    required
    style={{ paddingRight: '40px' }} // Add padding to make space for the eye icon
  />
  <span
    className="password-toggle-icon"
    onClick={togglePasswordVisibility}
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    }}
  >
    {showPassword ? (
      <i className="fas fa-eye-slash"></i>
    ) : (
      <i className="fas fa-eye"></i>
    )}
  </span>
</div>
          <div className="d-flex justify-content-center w-100">
            <a href="#forgotPasswordModal" className="forgot-password-link" onClick={handleForgotPassword}>
              Forgot Password?
            </a>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-primary" type="submit" disabled={isLoggingIn}>
              {isLoggingIn ? 'Logging in...' : 'Login'}
            </button>
          </div>
          <div className="text-center mt-3 dont-have-account">
            <span>
              Don't have an account?
              <button
                type="button"
                className="btn btn-link signup-button"
                onClick={handleSignUp}
              >
                Sign Up
              </button>
            </span>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
